import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Layout, Affix } from "antd";
import Sidenav from "./Sidenav";
import Header from "./Header";
import { useSelector, useDispatch } from "react-redux";
import { getGroups } from "../../redux/actions/groups";

const { Header: AntHeader, Content, Sider } = Layout;

function Main({ children }) {
  const [visible, setVisible] = useState(false);
  const [placement, setPlacement] = useState("right");
  const [sidenavColor, setSidenavColor] = useState("#a3289a");
  const [sidenavType, setSidenavType] = useState("transparent");
  const [fixed, setFixed] = useState(false);
  const { groups } = useSelector((state) => state.groups);

  const { user, Theme } = useSelector((state) => state.auth);

  const openDrawer = () => setVisible(!visible);
  const handleSidenavType = (type) => setSidenavType(type);
  const handleSidenavColor = (color) => setSidenavColor(color);
  const handleFixedNavbar = (type) => setFixed(type);
  const dispatch = useDispatch();
  let { pathname } = useLocation();
  pathname = pathname.replace("/", "");
  const [isMobile, setIsMobile] = useState(window.innerWidth < 850);

  const handleWindowResize = () => {
    setIsMobile(window.innerWidth < 850);
  };

  useEffect(() => {
    // Add event listener for window resize
    window.addEventListener("resize", handleWindowResize);
    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  useEffect(() => {
    if (pathname === "rtl") {
      setPlacement("left");
    } else {
      setPlacement("right");
    }
  }, [pathname]);

  useEffect(() => {
    dispatch(getGroups());
  }, []);

  return (
    <Layout
      style={{
        backgroundImage:
          Theme.BACKGROUND === "#000"
            ? "linear-gradient(45deg, #07080A 40%,  #07080A 100%)"
            : "#f1f1fb",
        background:
          Theme.BACKGROUND === "#000"
            ? "linear-gradient(45deg, #07080A 40%,  #07080A 100%)"
            : "#f1f1fb",
        width: "100vw",
        position: "relative",
        bottom: 9.5
      }}
      className={`layout-dashboard ${
        pathname === "profile" ? "layout-profile" : ""
      } ${pathname === "rtl" ? "layout-dashboard-rtl" : ""}`}
    >
      {!isMobile && (
        <Sider
          breakpoint="lg"
          trigger={null}
          width={"6%"}
          theme="light"
          className={`sider-primary ant-layout-sider-primary ${
            sidenavType === "#fff" ? "active-route" : ""
          }`}
          style={{
            backgroundRepeat: "no-repeat",
            height: "105vh",
            backgroundSize: "cover",
            opacity: 1,
            boxShadow: "0px 6px 12px rgba(255, 255, 255, 0.05)", // Adding a shadow effect
            backgroundColor: Theme.SIDEBAR,
            display: "flex", // Add flex display
            flexDirection: "column", // Stack items vertically
            justifyContent: "center", // Center items vertically
            alignItems: "center" // Center items horizontally
          }}
        >
          <Sidenav color={"black"} user={user} groups={groups} />
        </Sider>
      )}
      <Layout>
        {fixed ? (
          <Affix>
            <AntHeader className={`${fixed ? "ant-header-fixed" : ""}`}>
              <Header
                onPress={openDrawer}
                name={pathname}
                subName={pathname}
                handleSidenavColor={handleSidenavColor}
                handleSidenavType={handleSidenavType}
                handleFixedNavbar={handleFixedNavbar}
              />
            </AntHeader>
          </Affix>
        ) : (
          <AntHeader className={`${fixed ? "ant-header-fixed" : ""}`}>
            <Header
              onPress={openDrawer}
              name={pathname}
              subName={pathname}
              handleSidenavColor={handleSidenavColor}
              handleSidenavType={handleSidenavType}
              handleFixedNavbar={handleFixedNavbar}
            />
          </AntHeader>
        )}
        <Content className="content-ant">{children}</Content>
      </Layout>
    </Layout>
  );
}

export default Main;
