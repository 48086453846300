import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Col, Modal, Row, Typography, Spin } from "antd";

import {
  useHistory,
  useLocation,
  useParams
} from "react-router-dom/cjs/react-router-dom.min";
import { Button } from "react-bootstrap";
import { IoChevronBackOutline } from "react-icons/io5";
import customblank from "../../assets/images/customblank.jpg";
import { analytics } from "../../firebase-config";
import { logEvent } from "firebase/analytics";
import {
  copyBlockWeek,
  getSingleMesocycle,
  resetMesocycleStatus,
  resetWorkoutStatus
} from "../../redux/actions/workouts";
import { getSingleClient } from "../../redux/actions/clients";
import { CopyOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { IoPencilSharp } from "react-icons/io5";
import { CiDumbbell } from "react-icons/ci";
import CreateMesocycleForm from "./BlockForm";
import EditWorkoutForm from "./EditWorkoutForm";
import CreateBlockForm from "./BlockForm";
import CreateWorkoutForm from "./CreateWorkoutForm";
import moment from "moment";
import WorkoutDetails from "./WorkoutDetails";
import meso from "../../assets/images/mesocycle.png";

const { Text } = Typography;

const Block = () => {
  const dispatch = useDispatch();
  const { Theme } = useSelector((state) => state.auth);
  const { client } = useSelector((state) => state.clients);
  const { clientId, id } = useParams();
  const { mesocycle, mesocycleStatus, workoutStatus, workout } = useSelector(
    (state) => state.workouts
  );
  const [sourceWeek, setSourceWeek] = useState(null);
  const [targetWeeks, setTargetWeeks] = useState([]);
  const [createModalVisible, setCreateModalVisible] = useState(false);
  const [copyModalVisible, setCopyModalVisible] = useState(false);
  const [editWorkoutModalOpen, setEditWorkoutModalOpen] = useState(false);
  const [copyError, setCopyError] = useState(false);
  const [copyLoading, setCopyLoading] = useState(false);

  const [loading, setLoading] = useState(true);
  const [fullDescript, setFullDescript] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedWorkout, setSelectedWorkout] = useState({});
  const [workoutUpdated, setWorkoutUpdated] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (mesocycle && !workoutUpdated) {
      if (
        mesocycle.length > 0 &&
        mesocycle[0]?.weeks?.length > 0 &&
        mesocycle[0].weeks[0].workouts.length > 0
      ) {
        const firstWeekFirstWorkout = mesocycle[0].weeks[0].workouts[0];
        setSelectedWorkout(firstWeekFirstWorkout);
      } else if (mesocycle[0]?.out_of_date_workouts?.length > 0) {
        // If there are no workouts in the weeks, select the first out-of-date workout
        const firstOutOfDateWorkout = mesocycle[0].out_of_date_workouts[0];
        setSelectedWorkout(firstOutOfDateWorkout);
      }
    }
    setWorkoutUpdated(false);
  }, [mesocycle]);

  useEffect(() => {
    return () => {
      setSelectedWorkout({});
    };
  }, []);

  useEffect(() => {
    setCopyLoading(false);
    if (mesocycleStatus === "updated") {
      setEditModalOpen(false);
      dispatch(getSingleMesocycle(clientId, id)).then(
        (response) => dispatch(resetMesocycleStatus()),
        setLoading(true),
        setTimeout(() => {
          setLoading(false);
        }, 1100)
      );
    }
    if (mesocycleStatus === "week success") {
      setCopyModalVisible(false);
      setTargetWeeks([]);
      dispatch(getSingleMesocycle(client?.id, mesocycle[0]?.id));
      dispatch(resetMesocycleStatus());
    } else if (mesocycleStatus === "week fail") {
      setCopyError(true);
    }
  }, [mesocycleStatus]);

  useEffect(() => {
    if (
      workoutStatus == "success" ||
      workoutStatus == "updated" ||
      workoutStatus == "deleted"
    ) {
      dispatch(getSingleMesocycle(clientId, id)).then(
        (response) => setSelectedWorkout(workout[0]),
        setWorkoutUpdated(true),
        dispatch(resetWorkoutStatus())
      );
    }
  }, [workoutStatus]);

  useEffect(() => {
    logEvent(analytics, "page_view", { title: "shit piss" });

    dispatch(getSingleClient(clientId)).then(() =>
      dispatch(getSingleMesocycle(clientId, id))
    );
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  const history = useHistory();

  const handleGoToClient = () => {
    if (location.state && location.state.fromMacro) {
      history.goBack();
    } else {
      history.push(`/client/${client.id}`, { fromWorkout: true });
    }
  };

  const formatDate = (dateString) => {
    const splitString = dateString.split("-");
    const formattedString = `${splitString[1]}-${splitString[2]}-${splitString[0]}`;
    const options = { year: "numeric", month: "numeric", day: "numeric" };
    const date = new Date(formattedString);
    return date.toLocaleDateString("en-US", options);
  };

  const handleCloseModal = () => {
    setCreateModalVisible(false);
    setCopyModalVisible(false);
    setEditWorkoutModalOpen(false);
  };

  const closeEditModal = () => {
    setEditWorkoutModalOpen(false);
  };

  return (
    <div
      style={{
        marginTop: "9vw",
        position: "relative",
        right: "2.5%",
        width: "85vw"
      }}
      className={`fade-in-slide-up-element ${
        true ? "fade-in-slide-up-animation" : ""
      }`}
    >
      <div
        onClick={handleGoToClient}
        style={{
          bottom: "10vw",
          position: "relative",
          cursor: "pointer",
          right: "10%"
        }}
      >
        <IoChevronBackOutline
          style={{
            top: ".1vw",
            marginRight: ".5%",
            position: "relative",
            color: Theme.TEXT
          }}
        />
        <Text style={{ color: Theme.TEXT }}>Back</Text>
      </div>
      <Col
        style={{
          width: "85vw",
          position: "relative",
          bottom: loading ? "27.5vw" : "9vw",
          minHeight: "100vh",
          right: "12%",
          background: Theme.CARD,
          borderWidth: 0,
          borderRadius: 10,
          boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.175)"
        }}
      >
        {loading ? (
          <Col
            align="middle"
            justify="center"
            style={{ marginTop: "25%", position: "relative", top: "15%" }}
          >
            <Spin
              style={{ marginTop: "25%", position: "relative", top: "15%" }}
              size="large"
            />
          </Col>
        ) : (
          <div>
            {mesocycle && mesocycle[0] && (
              <div>
                <Row
                  style={{
                    paddingLeft: "2vw",
                    marginBottom: mesocycle[0].description ? "1vw" : "2vw",
                    background: "#000",
                    borderTopLeftRadius: 10,
                    borderTopRightRadius: 10,
                    paddingTop: "1vw",
                    paddingBottom: "1vw"
                  }}
                >
                  <img
                    style={{
                      height: "3.5vw",
                      width: "5.9vw",
                      borderRadius: 10,
                      objectFit: "cover",
                      marginRight: "1vw"
                    }}
                    src={meso}
                  />
                  <Col>
                    <Text
                      style={{
                        fontSize: "1.2vw",
                        color: Theme.WHITE,
                        fontWeight: "bold"
                      }}
                    >
                      {mesocycle[0].name}
                    </Text>
                    {mesocycle[0].description && (
                      <div style={{ width: "30vw" }}>
                        {mesocycle[0].description.length < 230 ? (
                          <Text
                            style={{ fontSize: ".55vw", color: Theme.WHITE }}
                          >
                            {mesocycle[0].description}
                          </Text>
                        ) : fullDescript ? (
                          <Text
                            style={{ fontSize: ".55vw", color: Theme.WHITE }}
                          >
                            {mesocycle[0].description}{" "}
                            <span
                              onClick={() => setFullDescript(false)}
                              style={{
                                fontSize: ".65vw",
                                color: Theme.PRIMARY,
                                fontWeight: "bold",
                                cursor: "pointer"
                              }}
                            >
                              Show Less
                            </span>
                          </Text>
                        ) : (
                          <Row>
                            <Text
                              style={{ fontSize: ".55vw", color: Theme.WHITE }}
                            >
                              {mesocycle[0].description.slice(0, 230)}...{" "}
                              <span
                                onClick={() => setFullDescript(true)}
                                style={{
                                  fontSize: ".65vw",
                                  color: Theme.PRIMARY,
                                  fontWeight: "bold",
                                  cursor: "pointer"
                                }}
                              >
                                Show More
                              </span>
                            </Text>{" "}
                          </Row>
                        )}
                      </div>
                    )}
                  </Col>

                  <Row style={{ position: "absolute", right: "2vw" }}>
                    <Col style={{ marginLeft: "4vw" }}>
                      <Text
                        style={{
                          fontSize: ".9vw",
                          color: Theme.WHITE,
                          fontWeight: "bold"
                        }}
                      >
                        Start Date
                      </Text>
                      <div>
                        <Text style={{ fontSize: ".9vw", color: Theme.WHITE }}>
                          {formatDate(mesocycle[0].start_date)}
                        </Text>
                      </div>
                    </Col>
                    <Col style={{ marginLeft: "4vw" }}>
                      <Text
                        style={{
                          fontSize: ".9vw",
                          color: Theme.WHITE,
                          fontWeight: "bold"
                        }}
                      >
                        Length
                      </Text>
                      <div>
                        <Text style={{ fontSize: ".9vw", color: Theme.WHITE }}>
                          {mesocycle[0].numOfWeeks} Weeks
                        </Text>
                      </div>
                    </Col>
                    <Button
                      color={"PRIMARY"}
                      style={{
                        marginLeft: "4vw",
                        cursor: "pointer",
                        height: "2dvw",
                        width: "3vw",
                        // backgroundImage:
                        //   "linear-gradient(60deg, #C04848 25%, #480048 120%)",
                        borderRadius: 10,
                        borderWidth: 0
                      }}
                      onClick={() => setEditModalOpen(true)}
                    >
                      <div style={{ flexDirection: "column" }}>
                        <div style={{ flexDirection: "row" }}>
                          <Text
                            style={{
                              color: Theme.BLACK,
                              fontSize: ".65vw",
                              textAlign: "center",

                              fontWeight: "bold"
                            }}
                          >
                            <IoPencilSharp size={".55vw"} /> Edit
                          </Text>
                        </div>
                      </div>
                    </Button>
                  </Row>
                </Row>
                {/* <hr /> */}
                <Row style={{ marginLeft: "0vw" }}>
                  <Col
                    align="start"
                    style={{
                      width: "17vw",
                      marginLeft: "0vw",
                      marginRight: "2vw",
                      position: "relative",
                      position: "relative",
                      // right: "1vw",
                      borderRightColor: Theme.TEXT2,
                      paddingBottom: "5vw"
                      // background: Theme.BACKGROUND
                    }}
                  >
                    <Col
                      style={{
                        marginRight: "3vw",
                        position: "relative",
                        left: "2vw"
                      }}
                    >
                      <Col align="middle" justify={"center"}></Col>
                      {mesocycle[0]?.weeks?.map((week, index) => (
                        <div key={index} style={{ marginTop: "1vw" }}>
                          {/* Week Workouts */}
                          <Col
                            style={{
                              marginBottom:
                                week.workouts.length === 0 ? "3vw" : "1.5vw"
                            }}
                          >
                            <Text
                              style={{
                                fontSize: ".9vw",
                                color: Theme.TEXT,
                                fontWeight: "bold"
                              }}
                            >
                              Week {index + 1}
                            </Text>
                            <Text
                              style={{
                                color: Theme.TEXT2,
                                marginLeft: ".5vw",
                                fontSize: ".85vw"
                              }}
                            >
                              {moment(week.start_date).format("MM/DD/YYYY")} -{" "}
                              {moment(week.end_date).format("MM/DD/YYYY")}
                            </Text>
                            <Button
                              color={"PRIMARY"}
                              style={{
                                paddingRight: ".5vw",
                                // height: "1.5vw",
                                cursor: "pointer",

                                // top: "1.85vw",
                                backgroundImage:
                                  "linear-gradient(60deg, #C04848 25%, #480048 120%)",
                                borderRadius: 10,
                                borderWidth: 0
                                // opacity: 0.9,
                              }}
                              onClick={() => {
                                setCopyModalVisible(true);
                                setSourceWeek(index + 1);
                              }}
                            >
                              <div style={{ flexDirection: "column" }}>
                                <div style={{ flexDirection: "row" }}>
                                  <Text
                                    style={{
                                      color: "white",
                                      fontSize: ".65vw",
                                      textAlign: "center",

                                      fontWeight: "bold",
                                      marginBottom: 20
                                    }}
                                  >
                                    <CopyOutlined
                                      style={{
                                        position: "relative",
                                        fontSize: ".7vw",
                                        color: Theme.WHITE,
                                        cursor: "pointer"
                                      }}
                                    />{" "}
                                    Copy Week
                                  </Text>
                                </div>
                              </div>
                            </Button>

                            {week?.workouts?.map((workout) => (
                              <Col
                                key={workout.id}
                                style={{
                                  marginTop: ".5vw",
                                  background:
                                    selectedWorkout.id === workout.id
                                      ? Theme.PRIMARY
                                      : Theme.BACKGROUND,
                                  borderWidth: 0,
                                  backgroundImage:
                                    selectedWorkout.id === workout.id
                                      ? "linear-gradient(60deg, #000000 5%, #353939 85%)"
                                      : null,
                                  cursor: "pointer",
                                  minWidth: "13vw",
                                  borderRadius: 5,
                                  boxShadow:
                                    "1px 1px 1px 1px rgba(0, 0, 0, 0.175)",
                                  padding: "10px",
                                  paddingTop: "5%",
                                  paddingBottom: "5%",
                                  marginBottom: ".5vw",
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis"
                                }}
                                onClick={() => setSelectedWorkout(workout)}
                              >
                                <Col
                                  align="start"
                                  style={{ marginLeft: ".25vw" }}
                                >
                                  <div style={{ marginLeft: ".5vw" }}>
                                    <Text
                                      style={{
                                        color: Theme.PRIMARY,
                                        fontSize: ".65vw",
                                        fontWeight: "bold"
                                      }}
                                    >
                                      {moment(workout.date).format(
                                        "MMMM DD, YYYY"
                                      )}
                                    </Text>
                                  </div>
                                  <div
                                    style={{
                                      width: "85%",
                                      marginLeft: ".5vw",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis"
                                    }}
                                  >
                                    <Text
                                      style={{
                                        color:
                                          selectedWorkout.id === workout.id
                                            ? Theme.WHITE
                                            : Theme.TEXT,
                                        fontSize: ".7vw",
                                        fontWeight: "bold",
                                        overflow: "hidden",
                                        whiteSpace: "nowrap",
                                        textOverflow: "ellipsis"
                                      }}
                                    >
                                      {workout.title}
                                    </Text>
                                  </div>
                                </Col>
                              </Col>
                            ))}
                          </Col>
                        </div>
                      ))}

                      {/* Out of Range Workouts Section */}
                      {mesocycle[0]?.out_of_date_workouts?.length > 0 && (
                        <div style={{ marginTop: "1vw" }}>
                          <Text
                            style={{
                              fontSize: ".8vw",
                              color: Theme.TEXT,
                              fontWeight: "bold"
                            }}
                          >
                            Outside Date Range
                          </Text>
                          {/* Out of Range Workouts */}
                          {mesocycle[0]?.out_of_date_workouts?.map(
                            (outOfRangeWorkout) => (
                              <Col
                                key={outOfRangeWorkout.id}
                                style={{
                                  marginTop: "8px",
                                  background: "red",
                                  borderWidth: 0,
                                  cursor: "pointer",
                                  minWidth: "13vw",
                                  borderRadius: "5px",
                                  boxShadow:
                                    "1px 1px 1px 1px rgba(0, 0, 0, 0.175)",
                                  padding: "10px",
                                  paddingTop: "5%",
                                  paddingBottom: "5%",
                                  marginBottom: ".5vw",
                                  lineHeight: 0.85
                                }}
                                onClick={() =>
                                  setSelectedWorkout(outOfRangeWorkout)
                                }
                              >
                                <div style={{ marginLeft: ".5vw" }}>
                                  <Text
                                    style={{
                                      color: Theme.WHITE,
                                      fontSize: ".65vw",
                                      fontWeight: "bold"
                                    }}
                                  >
                                    {moment(outOfRangeWorkout.date).format(
                                      "MMMM DD, YYYY"
                                    )}
                                  </Text>
                                </div>
                                <div
                                  style={{ width: "80%", marginLeft: ".5vw" }}
                                >
                                  <Text
                                    style={{
                                      color: Theme.WHITE,
                                      fontSize: ".7vw",
                                      fontWeight: "bold",
                                      overflow: "hidden",
                                      whiteSpace: "nowrap",
                                      textOverflow: "ellipsis"
                                    }}
                                  >
                                    {outOfRangeWorkout.title}
                                  </Text>
                                </div>
                              </Col>
                            )
                          )}
                        </div>
                      )}
                    </Col>
                  </Col>
                  {mesocycle[0]?.weeks?.every(
                    (week) => week.workouts.length === 0
                  ) && mesocycle[0].out_of_date_workouts.length === 0 ? (
                    <Col
                      align="middle"
                      style={{
                        // marginLeft: "5vw",
                        position: "relative",
                        right: "5%",
                        marginTop: "5vw",
                        width: "70%"
                      }}
                    >
                      <CiDumbbell size={"3vw"} style={{ color: Theme.TEXT }} />
                      <div>
                        <Text style={{ fontSize: "1vw", color: Theme.TEXT }}>
                          No Workouts for this Block
                        </Text>
                      </div>
                      <div>
                        <Button
                          color={"PRIMARY"}
                          style={{
                            // width: "5vw",
                            // position: "absolute",
                            // right: "1vw",
                            padding: ".5vw",
                            marginTop: "1vw",
                            paddingRight: ".5vw",
                            // height: "1.5vw",
                            cursor: "pointer",

                            // top: "1.85vw",
                            backgroundImage:
                              "linear-gradient(60deg, #C04848 25%, #480048 120%)",
                            borderRadius: 10,
                            borderWidth: 0
                            // opacity: 0.9,
                          }}
                          onClick={() => setCreateModalVisible(true)}
                        >
                          <div style={{ flexDirection: "column" }}>
                            <div style={{ flexDirection: "row" }}>
                              <Text
                                style={{
                                  color: "white",
                                  fontSize: ".65vw",
                                  textAlign: "center",

                                  fontWeight: "bold"
                                }}
                              >
                                <PlusCircleOutlined
                                  style={{
                                    position: "relative",
                                    fontSize: ".7vw",
                                    color: Theme.WHITE,
                                    cursor: "pointer"
                                  }}
                                />{" "}
                                Add Workout
                              </Text>
                            </div>
                          </div>
                        </Button>
                      </div>
                    </Col>
                  ) : (
                    selectedWorkout !== null && (
                      <Col
                        style={{
                          background: Theme.BACKGROUND,
                          // backgroundImage: Theme.BACKGROUND !== "#121212" ?
                          // "linear-gradient(60deg, #C04848 25%, #480048 120%)" : null,
                          borderRadius: 20,
                          // marginLeft: "3vw",
                          marginTop: "1vw",
                          width: "65%"
                        }}
                        align={"middle"}
                        justify={"center"}
                      >
                        <Col style={{ marginTop: "5%" }}>
                          <WorkoutDetails
                            workoutData={selectedWorkout}
                            onBlock={true}
                          />
                        </Col>
                      </Col>
                    )
                  )}
                  {mesocycle && (
                    <div>
                      {mesocycle[0]?.weeks?.some(
                        (week) => week?.workouts?.length > 0
                      ) || mesocycle[0]?.out_of_date_workouts?.length > 0 ? (
                        <Col align={"end"} style={{ marginLeft: "3vw" }}>
                          <Button
                            color={"PRIMARY"}
                            style={{
                              padding: ".5vw",
                              top: "7vw",
                              paddingRight: ".5vw",
                              backgroundImage:
                                "linear-gradient(60deg, #C04848 25%, #480048 120%)",
                              borderRadius: 10,
                              borderWidth: 0,
                              cursor: "pointer"
                            }}
                            onClick={() => setCreateModalVisible(true)}
                          >
                            <div style={{ flexDirection: "column" }}>
                              <div style={{ flexDirection: "row" }}>
                                <Text
                                  style={{
                                    color: "white",
                                    fontSize: ".65vw",
                                    textAlign: "center",
                                    fontWeight: "bold"
                                  }}
                                >
                                  <PlusCircleOutlined
                                    style={{
                                      position: "relative",
                                      fontSize: ".7vw",
                                      color: Theme.WHITE,
                                      cursor: "pointer"
                                    }}
                                  />{" "}
                                  Add Workout
                                </Text>
                              </div>
                            </div>
                          </Button>
                        </Col>
                      ) : null}
                    </div>
                  )}
                </Row>
              </div>
            )}
          </div>
        )}
        <Modal
          open={editModalOpen}
          onCancel={() => setEditModalOpen(false)}
          cancelButtonProps={{ style: { display: "none" } }}
          footer={[<Col align={"center"} justify={"center"}></Col>]}
          width={"35vw"}
          className={
            Theme.BACKGROUND === "#000"
              ? "customAntdModalDark"
              : "customAntdModalLight"
          }
        >
          <Col align="middle" style={{ marginTop: "1vw" }}>
            <Col>
              {" "}
              <img
                style={{
                  height: "4vw",
                  width: "4vw",
                  objectFit: "cover"
                }}
                src={meso}
              />{" "}
              <div>
                {" "}
                <Text
                  style={{
                    fontWeight: "bold",
                    fontSize: ".95dvw",
                    bottom: "1vw",
                    position: "relative",
                    color: Theme.TEXT,
                    justify: "center"
                  }}
                >
                  Update Block
                </Text>
              </div>
            </Col>

            <Row
              aling="middle"
              justify={"center"}
              style={{ marginTop: "1vw", paddingBottom: "1.5vw" }}
            >
              <CreateMesocycleForm
                blockData={mesocycle}
                modalOpen={editModalOpen}
              />
            </Row>
          </Col>
        </Modal>
        <Modal
          open={createModalVisible}
          onCancel={() => setCreateModalVisible(false)}
          cancelButtonProps={{ style: { display: "none" } }}
          closeIcon={<span></span>}
          footer={[<Col align={"center"} justify={"center"}></Col>]}
          width={"90vw"}
          style={{ top: "2vw" }}
          className={
            Theme.BACKGROUND === "#000"
              ? "customAntdModalDark"
              : "customAntdModalLight"
          }
        >
          <CreateWorkoutForm closeModal={handleCloseModal} meso={mesocycle} />
        </Modal>
        <Modal
          open={editWorkoutModalOpen}
          onCancel={() => setEditWorkoutModalOpen(false)}
          cancelButtonProps={{ style: { display: "none" } }}
          closeIcon={<span></span>}
          footer={[<Col align={"center"} justify={"center"}></Col>]}
          width={"90vw"}
          style={{ top: "2vw" }}
          className={
            Theme.BACKGROUND === "#000"
              ? "customAntdModalDark"
              : "customAntdModalLight"
          }
        >
          <EditWorkoutForm
            closeModal={handleCloseModal}
            workoutData={selectedWorkout}
            editModalOpen={editWorkoutModalOpen}
            setModalOpen={closeEditModal}
            fromMeso={true}
          />
        </Modal>
        <Modal
          open={copyModalVisible}
          onCancel={() => setCopyModalVisible(false)}
          footer={[<Col align={"center"} justify={"center"}></Col>]}
          width={"30vw"}
          style={{ top: "2vw" }}
          className={
            Theme.BACKGROUND === "#000"
              ? "customAntdModalDark"
              : "customAntdModalLight"
          }
        >
          <Col
            align="middle"
            justify="center"
            style={{
              display: "flex", // Ensure flex layout
              flexDirection: "column", // Stack children vertically
              gap: "10px" // Add spacing between buttons
            }}
          >
            <Text
              style={{ fontSize: "1vw", color: Theme.TEXT, fontWeight: "bold" }}
            >
              Select Weeks
            </Text>
            {mesocycle &&
              mesocycle[0]?.weeks?.map((weekData, index) => {
                const isSelected = targetWeeks.includes(index + 1);
                if (index + 1 == sourceWeek) {
                  return null;
                }
                return (
                  <Button
                    key={index}
                    color="secondary"
                    textStyle={{ fontSize: 12, fontWeight: "700" }}
                    style={{
                      background: isSelected ? Theme.PRIMARY : Theme.BACKGROUND,
                      borderRadius: 10,
                      height: 30,
                      cursor: "pointer",
                      borderWidth: 0,
                      alignSelf: "center",
                      width: "20vw"
                    }}
                    onClick={() =>
                      targetWeeks.includes(index + 1)
                        ? setTargetWeeks(
                            targetWeeks.filter((week) => week !== index + 1)
                          )
                        : setTargetWeeks([...targetWeeks, index + 1])
                    }
                  >
                    <Text
                      style={{
                        color: isSelected ? Theme.WHITE : Theme.TEXT2
                      }}
                    >
                      Week {index + 1}
                    </Text>
                  </Button>
                );
              })}
            <Button
              color={"PRIMARY"}
              style={{
                width: "15vw",
                // position: "absolute",
                // right: "1vw",
                padding: ".5vw",
                marginTop: "1vw",
                paddingRight: ".5vw",
                // height: "1.5vw",
                cursor: "pointer",

                // top: "1.85vw",
                backgroundImage:
                  "linear-gradient(60deg, #C04848 25%, #480048 120%)",
                borderRadius: 10,
                borderWidth: 0,
                alignSelf: "center"
              }}
              onClick={() => {
                if (mesocycle) {
                  setCopyLoading(true);
                  dispatch(
                    copyBlockWeek(mesocycle[0]?.id, {
                      source_week: sourceWeek,
                      target_weeks: targetWeeks
                    })
                  );
                }
              }}
            >
              <div style={{ flexDirection: "column" }}>
                <div style={{ flexDirection: "row" }}>
                  {copyLoading ? (
                    <Spin size="medium" />
                  ) : (
                    <Text
                      style={{
                        color: "white",
                        fontSize: ".65vw",
                        textAlign: "center",

                        fontWeight: "bold"
                      }}
                    >
                      <CopyOutlined
                        style={{
                          position: "relative",
                          fontSize: ".7vw",
                          color: Theme.WHITE,
                          cursor: "pointer"
                        }}
                      />{" "}
                      Copy Week
                    </Text>
                  )}
                </div>
              </div>
            </Button>
            {!copyError && (
              <Text style={{ color: "red" }}>Error copying week</Text>
            )}
          </Col>
        </Modal>
      </Col>
    </div>
  );
};

export default Block;
