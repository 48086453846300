import {
  CREATE_CLIENT_SUCCESS,
  CREATE_CLIENT_FAIL,
  GET_CLIENTS,
  GET_SINGLE_CLIENT,
  RESET_CLIENTS_STATUS,
  UPDATE_CLIENT,
  DELETE_CLIENT,
  SEARCH_CLIENTS,
  GET_PROGRESS,
  GET_PROGRESS_JC,
  GET_TREND_REPORT,
  GET_TREND_REPORT_JC,
  RESET_CLIENT,
  CREATE_CLIENT_ACCOUNT_SUCCESS,
  CREATE_CLIENT_ACCOUNT_FAIL,
  RESET_CLIENT_ACCOUNT,
  GET_RANGE_PROFILE,
  GET_RANGE_PROFILES,
  CREATE_RANGE_PROFILE_SUCCESS,
  CREATE_RANGE_PROFILE_FAIL,
  UPDATE_RANGE_PROFILE,
  DELETE_RANGE_PROFILE,
  RESET_RANGE_PROFILE_STATUS,
} from "../actions/types";

const initialState = {
  clients: [],
  client: {},
  clientStatus: null,
  clientId: null,
  progress: [],
  progress_jc: {},
  total_clients: 0,
  curr_clients: 0,
  trend_report: {},
  trend_report_jc: {},
  clientAccountStatus: null,
  statusChecked: false,
  profiles: [],
  profileStatus: null,
};

function clientsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_CLIENTS:
      return {
        ...state,
        clients: action.payload,
        total_clients: action.total_count,
        curr_clients: action.total_count
      };
    case SEARCH_CLIENTS:
      return {
        ...state,
        clients: action.payload,
        curr_clients: action.curr_clients
      };
    case GET_SINGLE_CLIENT:
      return {
        ...state,
        client: action.payload
      };
    case CREATE_CLIENT_SUCCESS:
      return {
        ...state,
        clients: [...state.clients, action.payload.data],
        clientStatus: action.payload.status,
        clientId: action.payload.data.id
      };
    case CREATE_CLIENT_FAIL:
      return {
        ...state,
        clientStatus: action.payload
      };
    case UPDATE_CLIENT:
      return {
        ...state,
        client: action.payload.data,
        clientStatus: action.payload.status
      };
    case DELETE_CLIENT:
      return {
        ...state,
        client: {}
      };
    case CREATE_CLIENT_ACCOUNT_SUCCESS:
      return {
        ...state,
        clientAccountStatus: action.payload.status
      };
    case CREATE_CLIENT_ACCOUNT_FAIL:
      return {
        ...state,
        clientAccountStatus: action.payload,
        statusChecked: !state.statusChecked
      };

    case RESET_CLIENT_ACCOUNT:
      return {
        ...state,
        clientAccountStatus: null
      };
    case RESET_CLIENTS_STATUS:
      return {
        ...state,
        clientStatus: null
      };
    case GET_PROGRESS:
      return {
        ...state,
        progress: action.payload
      };
    case GET_PROGRESS_JC:
      return {
        ...state,
        progress_jc: action.payload
      };
    case GET_TREND_REPORT:
      return {
        ...state,
        trend_report: action.payload
      };
    case GET_TREND_REPORT_JC:
      return {
        ...state,
        trend_report_jc: action.payload
      };
    case RESET_CLIENT:
      return {
        ...state,
        client: initialState.client
      };
      case GET_RANGE_PROFILES:
        return {
            ...state,
            profiles: action.payload
        };

    case GET_RANGE_PROFILE:
        return {
            ...state,
            profile: action.payload
        };
    case CREATE_RANGE_PROFILE_SUCCESS:
        return {
            ...state,
            profiles: [...state.clients, action.payload.data],
            profile: action.payload.data,
            profileStatus: action.payload.status
        };
    case CREATE_RANGE_PROFILE_FAIL:
        return {
            ...state,
            profileStatus: action.payload
        };
    case UPDATE_RANGE_PROFILE:
        return {
            ...state,
            profile: action.payload.data,
            profileStatus: action.payload.status
        };
    case DELETE_RANGE_PROFILE:
        return {
            ...state,
            profile: {}
        };
    case RESET_RANGE_PROFILE_STATUS:
        return {
            ...state,
            profileStatus: null
        };
    default:
      return state;
  }
}

export default clientsReducer;
