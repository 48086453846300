import React, { useEffect, useState } from "react";
import { Button, Col } from "antd";
import "../../assets/styles/calendar.css"; // Import your external CSS
import Calendar from "react-calendar";
import { useDispatch, useSelector } from "react-redux";
import { copyWorkout } from "../../redux/actions/workouts"; // Your API action

const CopyWorkoutCalendar = ({ workout, setCopyModalOpen }) => {
  const dispatch = useDispatch();
  const [selectedDates, setSelectedDates] = useState([]);
  const { Theme } = useSelector((state) => state.auth);
  const { workouts, workoutStatus } = useSelector((state) => state.workouts);

  useEffect(() => {
    if (workoutStatus === "success") {
      setSelectedDates([]);
      setCopyModalOpen(false);
    }
  }, [workoutStatus]);

  const handleDateClick = (date) => {
    const formattedDate = date.toISOString().split("T")[0];
    setSelectedDates(
      (prevDates) =>
        prevDates.includes(formattedDate)
          ? prevDates.filter((d) => d !== formattedDate) // Deselect date
          : [...prevDates, formattedDate] // Select new date
    );
  };

  const handleCopyWorkout = () => {
    if (workout && selectedDates.length > 0) {
     dispatch(copyWorkout(workout[0].id, { dates: selectedDates }));
    }
  };

  const isDateWithWorkout = (date) => {
    return workouts.some((workout) => {
      const workoutDate = new Date(`${workout.date}T00:00:00Z`);
      return (
        workoutDate.getUTCDate() === date.getUTCDate() &&
        workoutDate.getUTCMonth() === date.getUTCMonth() &&
        workoutDate.getUTCFullYear() === date.getUTCFullYear()
      );
    });
  };

  // Function to get the workout data for a specific date
  const getWorkoutForDate = (date) => {
    return workouts.find((workout) => {
      const workoutDate = new Date(`${workout.date}T00:00:00Z`);
      return (
        workoutDate.getUTCDate() === date.getUTCDate() &&
        workoutDate.getUTCMonth() === date.getUTCMonth() &&
        workoutDate.getUTCFullYear() === date.getUTCFullYear()
      );
    });
  };

  const getDayClassName = (date) => {
    const baseClassName = "react-calendar__month-view__days__day";
    const formattedDate = date.toISOString().split("T")[0];
    const isSelected = selectedDates.includes(formattedDate);
    const isWorkoutDay = isDateWithWorkout(date);
    const workout = getWorkoutForDate(date);
    if (workout) {
      return `${baseClassName} workout-day-completed ${Theme.PRIMARY} ${
        Theme.BACKGROUND === "#000" ? "dark-theme" : "light-theme"
      }`;
    }
    if (isSelected) {
      return `${baseClassName} workout-day ${Theme.PRIMARY} ${
        Theme.BACKGROUND === "#000" ? "dark-theme" : "light-theme"
      }`;
    }

    return `${baseClassName} ${isSelected ? "selected-day" : ""} ${
      Theme.BACKGROUND === "#000" ? "dark-theme" : "light-theme"
    }`;
  };

  const isTileDisabled = ({ date }) => {
    // Disable dates that already have workouts
    return isDateWithWorkout(date);
  };

  return (
    <Col
      style={{
        zIndex: 100,
        flex: 1,
        position: "relative",
        width: "35vw",
        minHeight: "15vw",
        marginBottom: 10,
        background: Theme.CARD,
        borderWidth: 0
      }}
      className="criclebox h-full"
    >
      <Col
        justify={"center"}
        align={"center"}
        style={{
          zIndex: 100,
          flex: 1,
          position: "relative",
          width: "30vw",
          right: ".5vw",
          background: Theme.CARD
        }}
      >
        
          <Calendar
            onClickDay={handleDateClick}
            tileClassName={({ date }) => getDayClassName(date)}
            tileDisabled={isTileDisabled}
            className={`workout ${
              Theme.BACKGROUND === "#000" ? "dark-theme" : "light-theme"
            }`}
          />
        <Button
          type="primary"
          onClick={handleCopyWorkout}
          style={{ marginTop: "2vw" }}
        >
          Copy Workout
        </Button>
      </Col>
    </Col>
  );
};

export default CopyWorkoutCalendar;
