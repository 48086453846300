import React, { useEffect, useState } from "react";
import { Card, Col, Row, Typography, Modal, List } from "antd";
import { Button } from "react-bootstrap";
import { StyleSheet, css } from "aphrodite";
import { compKeys } from "./compKeys";
import ResultsSectionTitle from "./ResultsSectionTitle";
import sections from "../../constants/sections";
import ScoreIndicator from "../chart/ScoreIndicator";
import { IoCheckmarkCircleOutline } from "react-icons/io5";
import { useSelector } from "react-redux";
import ScoreCircle from "./ScoreCircle";

const { Text } = Typography;

function Compensations({ compensations, score }) {
  const { Theme } = useSelector((state) => state.auth);
  const styles = createStyles(Theme);
  const [modalVisible, setModalVisible] = useState(false);
  const [compModalVisible, setCompModalVisible] = useState(false);
  const [compTitle, setCompTitle] = useState("");
  const [compDescription, setCompDescription] = useState("");
  const [compOveractive, setCompOveractive] = useState("");
  const [compUnderactive, setCompUnderactive] = useState("");
  const [infoModalVisible, setInfoModalVisible] = useState(false);

  var filteredKeys = [];
  var array = [];
  const filterConcerns = () => {
    const result = compKeys.filter((concern) => {
      return array.find((selected) => selected === concern.compensation);
    });
    filteredKeys = result;
  };

  useEffect(() => {
    filteredKeys = [];
    array = [];
  }, []);

  for (const comp in compensations) {
    array.push(compensations[comp]);
  }
  if (array.length > compensations.length) {
    array = [];
    for (const comp in compensations) {
      array.push(compensations[comp]);
    }
  }

  return (
    <Col
      className="aoc"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        top: "2vw"
      }}
      justify="center"
      align="middle"
    >
      <div style={{ position: "relative", marginTop: 35 }}>
        <ScoreCircle score={Math.round(score)} height={65} />
      </div>
      <Row
        align="center"
        style={{ alignItems: "center" }}
        justify="center"
        gutter={10}
      >
        <Col
          align="center"
          span={24}
          style={{
            fontWeight: "bold",
            // left: "7.5%",
            width: "100%",
            position: "relative"
          }}
        >
          <div
            style={{
              fontWeight: "bold",
              marginTop: "8%",
              // left: "21%",
              position: "relative",
              alignItems: "center"
            }}
          >
            <ResultsSectionTitle section={sections[1]} />
          </div>
          {filterConcerns()}
          {filteredKeys.length > 0 ? (
            <List
              style={{ position: "relative" }}
              dataSource={filteredKeys}
              renderItem={(item) => (
                <List.Item
                  style={{
                    textAlign: "center",
                    border: "none",
                    marginTop: "3%",

                    width: "100%"
                  }}
                >
                  <Button
                    type="secondary"
                    style={styles.button}
                    onClick={async () => {
                      setCompTitle(item.title);
                      setCompDescription(item.description);
                      setCompOveractive(item.overactive);
                      setCompUnderactive(item.underactive);
                      setCompModalVisible(true);
                    }}
                  >
                    {item.title}
                  </Button>
                </List.Item>
              )}
            />
          ) : (
            <Col style={{ position: "relative", top: "5vw" }}>
              <div style={{ position: "relative" }}>
                <IoCheckmarkCircleOutline size={20} color={Theme.GRAD6} />
              </div>
              <Text
                style={{
                  fontSize: 13,
                  position: "relative",
                  color: Theme.TEXT
                }}
              >
                No Compensations Measured
              </Text>
            </Col>
          )}
        </Col>
      </Row>
    </Col>
  );
}

const createStyles = (theme) => ({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    width: "100%"
  },
  list: {
    alignSelf: "auto",
    padding: 10
  },
  button: {
    backgroundColor: theme.BACKGROUND,
    borderRadius: 10,
    border: 0,

    width: 175,
    height: "2.5vw",
    fontSize: 12,
    cursor: "pointer",
    fontWeight: "600",
    marginTop: 5,
    color: theme.TEXT
    // fontFamily: "sanserif",
  },
  title: {
    fontSize: 20,
    color: theme.BLACK,
    fontWeight: "600",
    fontFamily: "ArialBold"
  },
  accuracyText: {
    marginTop: 10,
    fontWeight: "400",
    color: theme.WHITE,
    fontSize: 15
  },
  howtoText: {
    fontWeight: "400",
    marginTop: 10,
    color: theme.WHITE
  },
  sectionText: {
    marginTop: 12,
    left: "7%",
    fontSize: ".85vw",
    fontWeight: "600",
    alignSelf: "center",
    position: "relative",
    color: theme.BLACK
  },
  video: {
    alignSelf: "center",
    width: 250,
    height: 250,
    alignSelf: "center",
    marginVertical: "10%",
    marginHorizontal: "2%"
  },
  compensationTitle: {
    fontWeight: "600",
    fontSize: "1vw",

    color: theme.PRIMARY
  },
  modalText: {
    color: theme.TEXT2,
    maxWidth: "85%",
    marginBottom: 20,
    textAlign: "center",
    fontSize: ".85vw",
    marginTop: 5
  },
  compensationSectionText: {
    color: theme.PRIMARY,
    fontSize: ".75vw",
    fontWeight: "bold",
    paddingVertical: 2,

    alignSelf: "center"
  }
});

export default Compensations;
