import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Col, Row, Typography } from "antd";
import Body from "./Body";
import ResultsScores from "./ResultsScores";
import SquatResults from "./SquatResults";
import PushResults from "./PushResults";
import RdlResults from "./RdlResults";
import LungeResults from "./LungeResults";
import BalanceResults from "./BalanceResults";
import Posture from "./Posture";
import HingeResults from "./HingeResults";
import LatLungeResults from "./LatLungeResults";
import {
  useHistory,
  useLocation
} from "react-router-dom/cjs/react-router-dom.min";
import CMJResults from "./CmjResults";
import runningblackman from "../../assets/images/logo.png";
import runningman from "../../assets/images/logowhite.png";

const { Text } = Typography;
let bodyHeight = 400;
let modalMargin = 0;

const PdfSections = ({ clientName, assessment }) => {
  const [isRowLayout, setIsRowLayout] = useState(window.innerWidth >= 1000);

  const { Theme } = useSelector((state) => state.auth);
  const [notes, setNotes] = useState(assessment?.notes);
  const overallScore = parseFloat(assessment.overallScore);
  const overall = [Math.round(overallScore)];
  const createdOn = new Date(assessment.created_on);
  const flex = [Math.round(parseInt(assessment.mobilityScore))];
  const stability = [Math.round(parseInt(assessment.stabilityScore))];
  const compensation = [Math.round(parseInt(assessment.compensationScore))];
  const scoreArray = [
    { title: "Overall", score: overall },
    { title: "Mobility", score: flex },
    { title: "Compensation", score: compensation },
    { title: "Stability", score: stability }
  ];
  const styles = createStyles(Theme);

 
  if (assessment.posture) {
  }
  const headerClass = styles.header;
  const headerTitleClass = styles.headerTitle;

  const checkFields = (assessment) => {
    const fieldsToCheck = [
      "hinge",
      "free_record",
      "r_lunge",
      "l_lunge",
      "r_lat_lunge",
      "l_lat_lunge",
      "r_balance",
      "l_balance",
      "r_rdl",
      "l_rdl",
      "posture",
      "push",
      "squat",
      "standard_squat"
    ];

    const allFieldsNull = fieldsToCheck.every(
      (field) => assessment[field] === null
    );
    const cmjHasValue = assessment.cmj !== null;

    return allFieldsNull && cmjHasValue;
  };

  const onlyCmj = checkFields(assessment);

  const listOfMovements = (item) => {
    var array = [];
    for (var [key, value] of Object.entries(item)) {
      if (
        key !== "created_on" &&
        key !== "id" &&
        key !== "overallScore" &&
        value !== null
      ) {
        if (key === "squat" && value !== null) {
          array.push("Squat");
        } else if (key === "standard_squat" && value !== null) {
          array.push("Squat");
        } else if (key === "posture" && value !== null) {
          array.push("Posture");
        } else if (key === "push" && value !== null) {
          array.push("Push");
        } else if (key === "r_balance" && value !== null) {
          array.push("Balance");
        } else if (key === "r_lunge" && value !== null) {
          array.push("Lunge");
        } else if (key === "r_lat_lunge" && value !== null) {
          array.push("Lunge");
        } else if (key === "r_rdl" && value !== null) {
          array.push("Hinge");
        } else if (key === "hinge" && value !== null) {
          array.push("Hinge");
        } else if (key === "free_record" && value !== null) {
          array.push("Free Record");
        }
      }
    }
    return array;
  };
  const movements = listOfMovements(assessment);
  let renderedCount = 0;

  if (overall == 0) {
    return (
      <div style={{ backgroundColor: "white", height: "100%" }}>
        <div style={{ backgroundColor: "white" }}></div>
      </div>
    );
  } else {
    return (
      <Col align="center" justify="center" style={{ paddingBottom: -100 }}>
        <Col
          align="center"
          justify="center"
          style={{
            alignItems: "center",
            display: "flex",
            top: 50,
            position: "relative",
            right: 10
          }}
        >
          <Col align="center" justify="center" style={{ marginTop: 15 }}>
            <Row
              align="center"
              justify="center"
              style={{
                alignSelf: "center",
                position: "relative",
                paddingBottom: "0vw"
              }}
            >
              <Col>
                <Card
                  style={{
                    zIndex: 100,
                    flex: 1,
                    position: "relative",
                    width: 755,
                    background: Theme.CARD,
                    borderWidth: 0,
                    borderRadius: 10,
                    height: 100,
                    
                  }}
                >
                  <img
                    style={{
                      height: 25,
                      position: "absolute",
                      top: 15,
                      left: 15
                    }}
                    src={
                      Theme.BACKGROUND === "#000" ? runningman : runningblackman
                    }
                    alt=""
                  />
                  <Col style={{ position: "absolute", top: 15, right: 20 }}>
                    <Text
                      id={"renderedText"}
                      style={{
                        color: Theme.TEXT,
                        fontSize: 11,
                        fontWeight: "bold"
                      }}
                    >
                      Date of Assessment
                    </Text>
                    <Col>
                      <Text
                        style={{
                          fontSize: ".80vw",
                          bottom: 5,
                          position: "relative",
                          fontWeight: "600",
                          color: Theme.PRIMARY,
                          fontSize: 11
                        }}
                      >
                        {createdOn.toLocaleDateString()}
                      </Text>
                    </Col>
                  </Col>
                  <Col
                    style={{
                      alignSelf: "center",
                      position: "relative",
                      textAlign: "center"
                      // top: 15
                    }}
                  >
                    <div>
                      <div style={{ marginTop: 30 }}>
                        {/* <p className={headerTitleClass}>Client:</p> */}
                        <Text
                          style={{
                            color: Theme.TEXT,
                            fontWeight: "bold",
                            // paddingLeft: "5%"
                            fontSize: 25,
                            position: "relative",
                            // bottom: 10,
                            shadowColor: Theme.TEXT,
                            shadowOffset: { width: 0, height: 2 },
                            shadowRadius: 4,
                            shadowOpacity: 0.2,
                            bottom: 15
                          }}
                        >
                          {clientName}
                        </Text>
                      </div>
                    </div>

                    <div></div>
                  </Col>
                </Card>
              </Col>
            </Row>
            {!onlyCmj && (
              <Row align="center" justify="center" style={{ marginTop: 25 }}>
                <Col align="center" justify="center" style={{ marginRight: 5 }}>
                  <Col
                    style={{
                      zIndex: 100,

                      position: "relative",
                      width: 370,
                      background: Theme.CARD,
                      borderRadius: 10,
                      borderWidth: 0,
                      height: 300,
                      marginRight: 5,
                      marginLeft: 5,
                      
                    }}
                    // className="criclebox h-full"
                  >
                    <div>
                      <div>
                        <Body
                          areasOfConcerns={assessment.areasOfConcern}
                          movements={movements}
                        />
                      </div>
                    </div>
                    {/* </div> */}
                  </Col>
                </Col>

                <Col
                  align="middle"
                  justify="center"
                  style={{
                    marginLeft: 5,
                    width: 370,
                    height: 300,
                    
                  }}
                >
                  <ResultsScores
                    isRowLayout={isRowLayout}
                    scoreArray={scoreArray}
                    headerClass={headerClass}
                    headerTitleClass={headerTitleClass}
                    createdOn={createdOn}
                  />
                </Col>
              </Row>
            )}
            <Col
              align="center"
              justify="center"
              style={{ position: "relative", left: 7.5 }}
            >
              {assessment.cmj !== null && (
                <Col
                  align="center"
                  justify="center"
                  style={{
                    marginTop: renderedCount % 2 !== 0 ? 150 : 25,
                    position: "relative"
                  }}
                >
                  <CMJResults
                    item={movements[1]}
                    cmj={assessment.cmj}
                    onlyCmj={onlyCmj}
                  />
                  <div style={{ visibility: "hidden" }}> {renderedCount++}</div>
                </Col>
              )}

              {assessment.squat && (
                <Col
                  align="center"
                  justify="center"
                  style={{
                    marginTop: renderedCount % 2 !== 0 ? 150 : 25,
                    position: "relative"
                  }}
                >
                  <SquatResults
                    item={movements[1]}
                    compensations={assessment?.squat?.squat_compensations}
                    squat={assessment.squat}
                    name="OVERHEAD"
                  />
                  <div style={{ visibility: "hidden" }}> {renderedCount++}</div>
                </Col>
              )}

              {assessment.standard_squat !== null && (
                <div
                  style={{
                    marginTop: renderedCount % 2 !== 0 ? 150 : 25,
                    position: "relative"
                  }}
                >
                  <SquatResults
                    item={movements[1]}
                    compensations={
                      assessment.standard_squat.squat_compensations
                    }
                    squat={assessment.standard_squat}
                    name="STANDARD"
                  />
                  <div style={{ visibility: "hidden" }}> {renderedCount++}</div>
                </div>
              )}

              {assessment.push !== null && (
                <Col
                  align="center"
                  justify="center"
                  style={{
                    marginTop: renderedCount % 2 !== 0 ? 150 : 25,
                    position: "relative"
                  }}
                >
                  <PushResults
                    item={movements[2]}
                    compensations={assessment.push.push_compensations}
                    push={assessment.push}
                  />
                  <div style={{ visibility: "hidden" }}> {renderedCount++}</div>
                </Col>
              )}

              {assessment.r_rdl !== null && (
                <div
                  style={{
                    marginTop: renderedCount % 2 !== 0 ? 150 : 25,
                    position: "relative"
                  }}
                >
                  <RdlResults
                    item={movements[2]}
                    compensations={assessment.r_rdl.rdl_compensations}
                    rdl={assessment.r_rdl}
                  />
                  <div style={{ visibility: "hidden" }}> {renderedCount++}</div>
                </div>
              )}

              {assessment.l_rdl !== null && (
                <div
                  style={{
                    marginTop: renderedCount % 2 !== 0 ? 150 : 25,
                    position: "relative"
                  }}
                >
                  <RdlResults
                    item={movements[2]}
                    compensations={assessment.l_rdl.rdl_compensations}
                    rdl={assessment.l_rdl}
                  />
                  <div style={{ visibility: "hidden" }}> {renderedCount++}</div>
                </div>
              )}

              {assessment.hinge !== null && (
                <div
                  style={{
                    marginTop: renderedCount % 2 !== 0 ? 150 : 25,
                    position: "relative"
                  }}
                >
                  <HingeResults
                    item={movements[1]}
                    compensations={assessment.hinge.hinge_compensations}
                    hinge={assessment.hinge}
                  />
                  <div style={{ visibility: "hidden" }}> {renderedCount++}</div>
                </div>
              )}

              {assessment.r_lunge !== null && (
                <div
                  style={{
                    marginTop: renderedCount % 2 !== 0 ? 150 : 25,
                    position: "relative"
                  }}
                >
                  <LungeResults
                    item={movements[2]}
                    compensations={assessment.r_lunge.lunge_compensations}
                    lunge={assessment.r_lunge}
                  />
                  <div style={{ visibility: "hidden" }}> {renderedCount++}</div>
                </div>
              )}

              {assessment.l_lunge !== null && (
                <div
                  style={{
                    marginTop: renderedCount % 2 !== 0 ? 150 : 25,
                    position: "relative"
                  }}
                >
                  <LungeResults
                    item={movements[2]}
                    compensations={assessment.l_lunge.lunge_compensations}
                    lunge={assessment.l_lunge}
                  />
                  <div style={{ visibility: "hidden" }}> {renderedCount++}</div>
                </div>
              )}

              {assessment.r_lat_lunge !== null && (
                <div
                  style={{
                    marginTop: renderedCount % 2 !== 0 ? 150 : 25,
                    position: "relative"
                  }}
                >
                  <LatLungeResults
                    item={movements[2]}
                    compensations={assessment.r_lat_lunge.lunge_compensations}
                    lunge={assessment.r_lat_lunge}
                  />
                  <div style={{ visibility: "hidden" }}> {renderedCount++}</div>
                </div>
              )}

              {assessment.l_lat_lunge !== null && (
                <div
                  style={{
                    marginTop: renderedCount % 2 !== 0 ? 150 : 25,
                    position: "relative"
                  }}
                >
                  <LatLungeResults
                    item={movements[2]}
                    compensations={assessment.l_lat_lunge.lunge_compensations}
                    lunge={assessment.l_lat_lunge}
                  />
                  <div style={{ visibility: "hidden" }}> {renderedCount++}</div>
                </div>
              )}

              {assessment.r_balance !== null && (
                <div
                  style={{
                    marginTop: renderedCount % 2 !== 0 ? 150 : 25,
                    position: "relative"
                  }}
                >
                  <BalanceResults
                    item={movements[2]}
                    compensations={assessment.r_balance.balance_compensations}
                    balance={assessment.r_balance}
                  />
                  <div style={{ visibility: "hidden" }}> {renderedCount++}</div>
                </div>
              )}

              {assessment.l_balance !== null && (
                <div
                  style={{
                    marginTop: renderedCount % 2 !== 0 ? 150 : 25,
                    position: "relative"
                  }}
                >
                  <BalanceResults
                    item={movements[2]}
                    compensations={assessment.l_balance.balance_compensations}
                    balance={assessment.l_balance}
                  />
                  <div style={{ visibility: "hidden" }}> {renderedCount++}</div>
                </div>
              )}

              {assessment.posture !== null && (
                <div
                  style={{
                    marginTop: renderedCount % 2 !== 0 ? 150 : 25,
                    position: "relative"
                  }}
                >
                  <Posture item={movements[2]} posture={assessment.posture} />
                  <div style={{ visibility: "hidden" }}> {renderedCount++}</div>
                </div>
              )}
            </Col>
          </Col>
        </Col>
      </Col>
    );
  }
};

export default PdfSections;

const createStyles = (theme) => ({
  container: {
    // flex: 1,

    backgroundColor: "#fff"

    // marginBottom: "10%"
  },
  scoreText: {
    // top: 50,
    paddingLeft: "5%",
    fontSize: 15,
    fontWeight: "bold",
    // paddingBottom: "3%"
    shadowColor: theme.BLACK,
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 4,
    shadowOpacity: 0.5
  },
  sectionText: {
    marginVertical: 10,
    top: 15,
    alignSelf: "center",
    fontSize: 25,
    fontWeight: "bold",
    paddingBottom: "8%",
    // paddingLeft: '5%'
    fontFamily: "ArialBold",
    color: theme.BLACK
  },
  headerTitle: {
    // top: 5,

    // alignSelf: "center",
    color: theme.BLACK,
    fontWeight: "600",
    fontSize: ".75vw",
    // paddingLeft: "5%"
    shadowColor: theme.BLACK,
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 4,
    shadowOpacity: 0.3,
    elevation: 2
  },
  headerText: {
    // top: 5,
    // alignSelf: "center",
    color: theme.BLACK,
    fontWeight: "bold",
    // paddingLeft: "5%"
    fontSize: "1.5vw",
    position: "relative",
    // bottom: 10,
    shadowColor: theme.BLACK,
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 4,
    shadowOpacity: 0.2
  },
  messageText: {
    color: "black",
    fontSize: 12,
    // top: 50,
    alignSelf: "center",
    fontSize: 15,
    paddingVertical: "3%",
    paddingHorizontal: "3%"
  },
  body: {
    // alignContent: 'center',
    // top: 50
    // width: "100%",
  },
  closeModal: {
    justifyContent: "flex-start",
    borderRadius: 10
    // zIndex: 20,
  },

  resultText: {
    color: theme.PRIMARY,
    fontSize: 14,
    fontWeight: "bold",
    padding: 8
  },
  header: {
    top: "20%",

    borderWidth: 0,

    flex: 1
  },
  concernSection: {
    // top: -300,
    paddingTop: "5%",
    alignSelf: "center",
    // margin: 6,
    backgroundColor: theme.WHITE,
    // marginVertical: theme.SIZES.BASE,
    borderWidth: 0,
    maxHeight: "100%",
    shadowColor: theme.BLACK,
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 4,
    shadowOpacity: 0.1,
    elevation: 2
  },
  pages: {
    position: "relative",
    // paddingTop: "5%",
    alignSelf: "center",
    // margin: 6,
    // backgroundColor: theme.WHITE,
    // marginVertical: theme.SIZES.BASE,
    height: 775,
    top: 50,

    borderWidth: 0

    // elevation: 2,
    // elevation: 2,
  },
  body: {
    position: "relative",
    paddingTop: "5%",
    alignSelf: "center",
    margin: 6,
    // backgroundColor: theme.WHITE,
    // marginVertical: theme.SIZES.BASE,
    height: bodyHeight,

    borderWidth: 0,

    width: "97.5%"
  },
  scoreSection: {
    // top: -300,
    paddingTop: "10%",
    alignSelf: "center",
    margin: 10,
    backgroundColor: theme.WHITE,
    // marginVertical: theme.SIZES.BASE,
    borderWidth: 0,
    maxHeight: "85%",

    shadowColor: theme.BLACK,
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 4,
    shadowOpacity: 0.7,
    elevation: 2
  },
  detailSection: {
    // top: -300,
    paddingTop: "10%",
    alignSelf: "center",
    margin: 10,
    backgroundColor: theme.WHITE,
    // marginVertical: theme.SIZES.BASE,
    borderWidth: 0,
    // maxHeight: '110%',

    shadowColor: theme.BLACK,
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 4,
    shadowOpacity: 0.1,
    elevation: 2
  },
  buttontext: {
    color: theme.WHITE,
    fontWeight: "bold",
    marginHorizontal: 5
    // paddingHorizontal: 5,
  },
  video: {
    alignSelf: "center",

    height: 500,
    marginVertical: "10%",
    marginHorizontal: "2%"
  },
  tourvideo: {
    alignSelf: "center",
    width: 200,
    height: 200,
    bottom: "5%",
    // marginVertical: "10%",
    marginHorizontal: "2%"
  },
  modalView: {
    // backgroundColor: "black",
    // backgroundColor: "white",
    borderRadius: 20,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2
    },
    alignSelf: "center",
    shadowOpacity: 0.25,
    shadowRadius: 4,
    top: modalMargin
    // elevation: 5,
  },
  bottomView: {
    flex: 1,
    justifyContent: "flex-end",
    top: 20,
    alignSelf: "center"
  },

  messageText: {
    color: theme.PRIMARY,
    fontWeight: "bold",
    paddingVertical: 8,
    paddingRight: 4,
    top: 50,
    fontFamily: "Arial"
  },
  icon: {
    // alignSelf: 'center',
    position: "absolute",
    opacity: 1,

    bottom: "25%",
    // left: '10%'
    zIndex: 25
  },
  lottieContainer: {
    flex: 1,
    alignSelf: "center",
    justifyContent: "center",

    position: "absolute",

    zIndex: 5
  },
  lottieStyle: {
    flex: 1,
    alignSelf: "center",
    justifyContent: "center",
    // backgroundColor: "#8d93de",
    opacity: 0.25,
    position: "absolute",

    zIndex: 5
  },
  modalText: {
    // color: theme.PRIMARY,
    flexWrap: "wrap",
    marginHorizontal: 5,
    left: 10,

    // textAlign: "center",
    alignSelf: "flex-start",
    paddingHorizontal: 10,
    marginVertical: 5,
    fontFamily: "ArgonExtra"
  },
  tourModal: {
    // backgroundColor: "black",
    backgroundColor: "white",
    borderRadius: 10,
    // padding: 150,
    bottom: 5,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2
    },
    alignSelf: "center",
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5
  },
  bottomView: {
    flex: 1,
    justifyContent: "center",
    top: 15,
    alignSelf: "center"
  }
});
