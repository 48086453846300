import axios from "axios";
import {
  GET_CLIENTS,
  CREATE_CLIENT_FAIL,
  GET_SINGLE_CLIENT,
  CREATE_CLIENT_SUCCESS,
  UPDATE_CLIENT,
  DELETE_CLIENT,
  SEARCH_CLIENTS,
  GET_PROGRESS,
  GET_TREND_REPORT,
  GET_TREND_REPORT_JC,
  RESET_CLIENT,
  GET_PROGRESS_JC,
  CREATE_CLIENT_ACCOUNT_SUCCESS,
  CREATE_CLIENT_ACCOUNT_FAIL,
  RESET_CLIENT_ACCOUNT,
  GET_RANGE_PROFILE,
  CREATE_RANGE_PROFILE_FAIL,
  CREATE_RANGE_PROFILE_SUCCESS,
  GET_RANGE_PROFILES,
  UPDATE_RANGE_PROFILE,
  DELETE_RANGE_PROFILE,
  RESET_RANGE_PROFILE_STATUS,
  SEARCH_RANGE_PROFILES

} from "./types";
 
import { REACT_APP_API_URL } from "../../env";
import { checkAuthenticated, generatePassword, signupClient } from "./auth";


// Create client
export const createClient = (data, createAccount) => async (dispatch) => {
  const token = await localStorage.getItem("access");
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "JWT " + token,
    },
  };
  try {
    const res = await axios.post(
      `${REACT_APP_API_URL}/api/clientsnew/`,
      data,
      config
    );
    dispatch({
      type: CREATE_CLIENT_SUCCESS,
      payload: res,
    });

    dispatch(createProgress(res.data.id));

    if (createAccount === true) {
      const tmpPassword = generatePassword(20);
      const account_type = 0;

      dispatch(
        signupClient(
          res.data.first_name,
          res.data.last_name,
          res.data.email,
          tmpPassword,
          tmpPassword,
          account_type
        )
      );
    }
  } catch (err) {
    dispatch({
      type: CREATE_CLIENT_FAIL,
      payload: err.response.status,
    });
   
  }
};

// Get Clients
export const getClients = (page_no = 1, trainer_id = "") => {
  try {
    return async (dispatch) => {
      const token = await localStorage.getItem("access");
      const config = {
        headers: {
          // 'Content-Type': 'application/json',
          Authorization: "JWT " + token,
        },
      };
      const res = await axios.get(`${REACT_APP_API_URL}/api/clientsnew/?page=${page_no}&trainer_id=${trainer_id}`, config);
      if (res.data) {
        
        dispatch({
          type: GET_CLIENTS,
          payload: res.data.results,
          total_count: res.data.count
        });
      } else {

      }
    };
  } catch (err) {
  
  }
};

// Get Clients with old endpoint
export const getClientsPersonal = (page_no = 1, trainer_id = "") => {
  try {
    return async (dispatch) => {
      const token = await localStorage.getItem("access");
      const config = {
        headers: {
          // 'Content-Type': 'application/json',
          Authorization: "JWT " + token,
        },
      };
      const res = await axios.get(`${REACT_APP_API_URL}/api/clients/`, config);
      if (res.data) {
        dispatch({
          type: GET_CLIENTS,
          payload: res.data,
          total_count: res.data.count
        });
      } else {

      }
    };
  } catch (err) {
  
  }
};

export const patchClientPersonal = (id, data) => async (dispatch) => {
  const token = await localStorage.getItem("access");
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "JWT " + token
    }
  };

  try {
    const res = await axios.patch(
      `${REACT_APP_API_URL}/api/clients/${id}/`,
      data,
      config
    );

    dispatch({
      type: UPDATE_CLIENT,
      payload: res
    });
  } catch (err) {
    console.error("Error updating client:", err);
    // Optionally handle the error by dispatching a failure action or showing a notification
  }
};
  

// Get Client Profile
export const getClientProfile = () => {
  try {
    return async (dispatch) => {
      const token = await localStorage.getItem("access");
      const config = {
        headers: {
          // 'Content-Type': 'application/json',
          Authorization: "JWT " + token,
        },
      };
      const res = await axios.get(
        `${REACT_APP_API_URL}/api/clientprofile/`,
        config
      );
      if (res.data) {
        dispatch({
          type: GET_CLIENTS,
          payload: res.data,
        });
      } else {

      }
    };
  } catch (err) {

  }
};

// Search Clients
export const searchClients = (searchTerm, group_ids = "", trainer_ids = "", trainer_group_ids="", page_no = 1) => {
  try {
    return async (dispatch) => {
      const token = await localStorage.getItem("access");
      const config = {
        headers: {
          // 'Content-Type': 'application/json',
          Authorization: "JWT " + token,
        },
      };
     
      const res = await axios.get(
        `${REACT_APP_API_URL}/api/clientsnew/?search=${searchTerm}&group_ids=${group_ids}&trainer_ids=${trainer_ids}&trainer_group_ids=${trainer_group_ids}&page=${page_no}`,
        config
      );
  
      if (res.data) {
        dispatch({
          type: SEARCH_CLIENTS,
          payload: res.data.results,
          curr_clients: res.data.count
        });
      } else {
      }
    };
  } catch (err) {
  }
};

// Update client
export const updateClient =
  (
    id,
    first_name,
    last_name,
    email,
    birthday,
    weight,
    height_feet,
    height_inches,
    weight_kg,
    height_cm,
    gender,
    trainer_id = null
  ) =>
  async (dispatch) => {
    const token = await localStorage.getItem("access");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + token
      }
    };
    const body = JSON.stringify({
      first_name,
      last_name,
      email,
      dob: birthday,
      weight,
      height_feet,
      height_inches,
      weight_kg,
      height_cm,
      gender,
      trainer_id
    });

    try {
      const res = await axios.put(
        `${REACT_APP_API_URL}/api/clientsnew/${id}/`,
        body,
        config
      );
      dispatch({
        type: UPDATE_CLIENT,
        payload: res.data
      });
    } catch (err) {}
  };

  export const patchClient = (id, data) => async (dispatch) => {
    const token = await localStorage.getItem("access");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + token,
      },
    };
  
    try {
      const res = await axios.patch(`${REACT_APP_API_URL}/api/clientsnew/${id}/`, data, config);
  
      dispatch({
        type: UPDATE_CLIENT,
        payload: res.data,
      });
    } catch (err) {
      console.error("Error updating client:", err);
      // Optionally handle the error by dispatching a failure action or showing a notification
    }
  };
  

  export const updateClientTrainers =
  (id, trainer_id) =>
  async (dispatch) => {
    const token = await localStorage.getItem("access");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + token,
      },
    };
    const body = JSON.stringify({
      trainer_id
    });

    try {
      const res = await axios.put(
        `${REACT_APP_API_URL}/api/clientsnew/${id}/`,
        body,
        config
      );
    } catch (err) {
  
    }
  };

  export const createClientAccountExisting =
    (first_name, last_name, email) => async (dispatch) => {
      const config = {
        headers: {
          "Content-Type": "application/json"
        }
      };
      const password = generatePassword(20);
      const account_type = 0;
      const re_password = password;
      const body = JSON.stringify({
        first_name,
        last_name,
        email,
        password,
        re_password,
        account_type
      });
      try {
        const res = await axios.post(
          `${REACT_APP_API_URL}/auth/users/`,
          body,
          config
        );
        dispatch({
          type: CREATE_CLIENT_ACCOUNT_SUCCESS,
          payload: res
        });
      } catch (err) {
        dispatch({
          type: CREATE_CLIENT_ACCOUNT_FAIL,
          payload: err.response.status
        });
      }
    };

export const resetClientAccountStatus = () => async (dispatch) => {
  dispatch({
    type: RESET_CLIENT_ACCOUNT
  });
};

export const clearAssessment = (id, data) => async (dispatch) => {
  const token = await localStorage.getItem("access");
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "JWT " + token,
    },
  };

  const body = {
    assigned_assessment: data,
  };

  try {
    const res = await axios.patch(
      `${REACT_APP_API_URL}/api/clientprofile/${id}/`,
      body,
      config
    );
    dispatch({
      type: UPDATE_CLIENT,
      payload: res.data,
    });
  } catch (err) {

  }
};

export const assignAssessment = (id, data) => async (dispatch) => {
  const token = await localStorage.getItem("access");
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "JWT " + token,
    },
  };

  const body = {
    assigned_assessment: data,
    assigned_assessment_type: "functional"
  };

  try {
    const res = await axios.patch(
      `${REACT_APP_API_URL}/api/clientsnew/${id}/`,
      body,
      config
    );
    dispatch({
      type: UPDATE_CLIENT,
      payload: res.data,
    });
  } catch (err) {

  }
};

export const updatePhoto = (id, data, account_type) => async (dispatch) => {
  const token = await localStorage.getItem("access");
  var endpoint = " "
  const formData = new FormData();

  if (account_type == 0) {
    endpoint = `${REACT_APP_API_URL}/api/clientsnew/${id}/`
  } else if (account_type == 1) {
    endpoint = `${REACT_APP_API_URL}/api/trainers/${id}/`
  } else if (account_type == 2) {
    endpoint = `${REACT_APP_API_URL}/api/organization/orgadmins/${id}/`
  }
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "JWT " + token,
    },
  };
  formData.append("profile_picture", data);

  try {
    const res = await axios.patch(
      endpoint,
      formData,
      config
    );
    /*
    dispatch({
      type: UPDATE_CLIENT,
      payload: res.data,
    });
    */
  } catch (err) {

  }
};

// Delete client
export const deleteClient = (id) => async (dispatch) => {
  const token = await localStorage.getItem("access");
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "JWT " + token,
    },
  };


  try {
    const res = await axios.delete(
      `${REACT_APP_API_URL}/api/clientsnew/${id}/`,
      config
    );
    dispatch({
      type: UPDATE_CLIENT,
      payload: res.data,
    });
  } catch (err) {

  }
};

// Get single client
export const getSingleClient = (id) => {
  try {
    return async (dispatch) => {
      const token = await localStorage.getItem("access");
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + token,
        },
      };

      try {
        const res = await axios.get(
          `${REACT_APP_API_URL}/api/clientsnew/${id}/`,
          config
        );
        if (res.data) {
          dispatch({
            type: GET_SINGLE_CLIENT,
            payload: res.data,
          });
        }
      } catch (err) {
    
      }
    };
  } catch (err) {

  }
};

export const resetClientStatus = () => ({
  type: "RESET_CLIENTS_STATUS",
});

export const resetClient = () => ({
  type: RESET_CLIENT,
});

// Create Progress
export const createProgress = (id) => async (dispatch) => {
  const token = await localStorage.getItem("access");
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "JWT " + token,
    },
  };
  const data = {
    client: id,
  };
  const body = JSON.stringify(data);
  try {
    const res = await axios.post(
      `${REACT_APP_API_URL}/api/progress/`,
      data,
      config
    );
  } catch (err) {

  }
};

// Update Progress
export const updateProgress = (data, id) => async (dispatch) => {
  const token = await localStorage.getItem("access");
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "JWT " + token,
    },
  };
  data.client = id;
  try {
    const res = await axios.post(
      `${REACT_APP_API_URL}/api/progressupdate/`,
      data,
      config
    );
  } catch (err) {

  }
};

// Get Progress
export const getProgress = (id) => {
  try {
    return async (dispatch) => {
      const token = await localStorage.getItem("access");
      var config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + token,
         
        },
        params: {
          id: id,
        },
      };

      try {
        const res = await axios.get(
          `${REACT_APP_API_URL}/api/progress/`,
          config
        );

        if (res.data) {
          dispatch({
            type: GET_PROGRESS,
            payload: res.data,
          });
        }
      } catch (err) {
    
      }
    };
  } catch (err) {

  }
};

export const getProgressJC = (id) => {
  try {
    return async (dispatch) => {
      const token = await localStorage.getItem("access");
      var config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + token,
         
        },
        params: {
          id: id,
        },
      };

      try {
        const res = await axios.get(
          `${REACT_APP_API_URL}/api/jointprogress/`,
          config
        );

        if (res.data) {
          dispatch({
            type: GET_PROGRESS_JC,
            payload: res.data,
          });
        }
      } catch (err) {
    
      }
    };
  } catch (err) {

  }
};

// Get Trend Report
export const getTrendReport = (state) => {
  try {
    return async (dispatch) => {
      const token = await localStorage.getItem("access");
      var config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + token
        },
        params: {
          period: state.selectedPeriod,
          year: state.selectedYear,
          group: state.selectedGroup.join(","),
          trainer: state.selectedTrainer.join(","),
          trainer_group: state.selectedTrainerGroup.join(","),
          minAge: state.selectedMinAge,
          maxAge: state.selectedMaxAge
        }
      };

      try {
        const res = await axios.get(
          `${REACT_APP_API_URL}/api/trendreports/`,
          config
        );

        if (res.data) {
          dispatch({
            type: GET_TREND_REPORT,
            payload: res.data,
          });
        
        }
      } catch (err) {
    
      }
    };
  } catch (err) {

  }
};

// Get Trend Report JC
export const getTrendReportJC = (state) => {
  try {
    return async (dispatch) => {
      const token = await localStorage.getItem("access");
      var config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + token
        },
        params: {
          period: state.selectedPeriod,
          year: state.selectedYear,
          group: state.selectedGroup.join(","),
          trainer: state.selectedTrainer.join(","),
          trainer_group: state.selectedTrainerGroup.join(",")
        }
      };

      try {
        const res = await axios.get(
          `${REACT_APP_API_URL}/api/jointtrends/`,
          config
        );

        if (res.data) {
          dispatch({
            type: GET_TREND_REPORT_JC,
            payload: res.data,
          });
        
        }
      } catch (err) {
    
      }
    };
  } catch (err) {

  }
};

// Create Profile
export const createProfile = (data) => async (dispatch) => {
  const token = await localStorage.getItem('access');
  const config = {
      headers: {
          'Content-Type': 'application/json',
          Authorization: 'JWT ' + token
      }
  };
  try {
      const res = await axios.post(`${REACT_APP_API_URL}/api/rangeprofiles/`, data, config);
      dispatch({
          type: CREATE_RANGE_PROFILE_SUCCESS,
          payload: res
      });
    return res.data

     
  } catch (err) {
      dispatch({
          type: CREATE_RANGE_PROFILE_FAIL,
          payload: err.response.status
      });
  }
};

// Get Profiles
export const getProfiles = () => {
  try {
      return async (dispatch) => {
          const token = await localStorage.getItem('access');
          const config = {
              headers: {
                  Authorization: 'JWT ' + token
              }
          };
          const res = await axios.get(`${REACT_APP_API_URL}/api/rangeprofiles/`, config);
          if (res.data) {

              dispatch({
                  type: GET_RANGE_PROFILES,
                  payload: res.data
              });
          } 
      };
  } catch (err) {
  }
};


export const getSingleProfile = (id) => {
  try {
    return async (dispatch) => {
      const token = await localStorage.getItem("access");
  
      var config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + token,
         
        },
      };

      try {
        const res = await axios.get(
          `${REACT_APP_API_URL}/api/rangeprofiles/${id}`, config,
          config
        );

        if (res.data) {
          dispatch({
            type: GET_RANGE_PROFILE,
            payload: res.data,
          });
        }
      } catch (err) {
      }
    };
  } catch (err) {

  }
};


// Update Profile
export const updateRangeProfile = (id, updatedFields) => async (dispatch) => {
  const token = await localStorage.getItem('access');
  const config = {
      headers: {
          'Content-Type': 'application/json',
          Authorization: 'JWT ' + token
      }
  };

  try {
      const res = await axios.patch(`${REACT_APP_API_URL}/api/rangeprofiles/${id}/`, updatedFields, config);

      dispatch({
          type: UPDATE_RANGE_PROFILE,
          payload: res
      });
  } catch (err) {
  }
};

export const searchRangeProfiles = (searchTerm) => {
  try {
    return async (dispatch) => {
      const token = await localStorage.getItem("access");
      const config = {
        headers: {
          // 'Content-Type': 'application/json',
          Authorization: "JWT " + token,
        },
      };
     
      const res = await axios.get(
        `${REACT_APP_API_URL}/api/rangeprofiles/?search=${searchTerm}`,
        config
      );
  
      if (res.data) {
        dispatch({
          type: SEARCH_RANGE_PROFILES,
          payload: res.data,
        });
      } else {
      }
    };
  } catch (err) {
  }
};

// Delete Profile
export const deleteProfile = (id) => async (dispatch) => {
  const token = await localStorage.getItem('access');
  const config = {
      headers: {
          'Content-Type': 'application/json',
          Authorization: `JWT ${token}` // Using Bearer instead of JWT
      }
  };

  try {
      const res = await axios.delete(`${REACT_APP_API_URL}/api/rangeprofiles/${id}/`, config);
      dispatch({
          type: DELETE_RANGE_PROFILE,
          payload: res.data
      });
  } catch (err) {
  }
};


export const resetProfileStatus = () => ({
  type: RESET_RANGE_PROFILE_STATUS
});