import React, { useEffect, useState } from "react";
import lottie from "lottie-web";
import animationData from "../../assets/dots.json"; // Replace with your Lottie animation JSON file
import { useDispatch, useSelector } from "react-redux";
import { load_user } from "../../redux/actions/auth";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getClients, getClientsPersonal } from "../../redux/actions/clients";

const LottieAnimation = () => {
  const dispatch = useDispatch();
  const fetchUser = () => dispatch(load_user());
  const { user, isAuthenticated } = useSelector((state) => state.auth);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    fetchUser().then((response) => setChecked(true));
    // Use lottie.loadAnimation() to load the animation into a DOM element
    const animationContainer = document.getElementById("lottie-container");
    if (animationContainer) {
      lottie.loadAnimation({
        container: animationContainer,
        animationData: animationData,
        loop: true, // Set to true if you want the animation to loop
        autoplay: true // Set to true to play the animation immediately
      });
    }
  }, []);
  const history = useHistory();

  useEffect(() => {
    if (checked && !isAuthenticated) {
      history.push("/sign-in");
    }
    // }  if (checked && isAuthenticated && )
  }, [user]);

  return (
    <div
      id="lottie-container"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "7.5vw",
        width: "7.5vw",
        position: "relative",
        bottom: "10vw"
      }}
    ></div>
  );
};

export default LottieAnimation;
