// workoutActions.js
import axios from "axios";
import {
  CREATE_WORKOUT_SUCCESS,
  CREATE_WORKOUT_FAIL,
  GET_WORKOUTS,
  GET_SINGLE_WORKOUT,
  UPDATE_WORKOUT,
  DELETE_WORKOUT,
  RESET_WORKOUT_STATUS,
  GET_EXERCISES,
  CREATE_EXERCISE_SUCCESS,
  CREATE_EXERCISE_FAIL,
  UPDATE_EXERCISE,
  DELETE_EXERCISE,
  RESET_EXERCISE_STATUS,
  CREATE_LIBRARY_EXERCISE_SUCCESS,
  CREATE_LIBRARY_EXERCISE_FAIL,
  CREATE_MESOCYCLE_SUCCESS,
  CREATE_MESOCYCLE_FAIL,
  GET_MEOSCYCLES,
  GET_SINGLE_MESOCYCLE,
  UPDATE_MESOCYCLE,
  DELETE_MESOCYCLE,
  RESET_MESOCYCLE_STATUS,
  GET_PROGRAMS,
  CREATE_PROGRAM_FAIL,
  UPDATE_PROGRAM,
  GET_SINGLE_PROGRAM,
  RESET_PROGRAM_STATUS,
  DELETE_PROGRAM,
  CREATE_PROGRAM_SUCCESS,
  COPY_WORKOUT_SUCCESS,
  COPY_WEEK_SUCCESS,
  COPY_WEEK_FAIL,
} from "./types";
import { REACT_APP_API_URL } from "../../env";

// Create workout
export const createWorkout = (data) => async (dispatch) => {
  try {
    const token = await localStorage.getItem("access");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + token,
      },
    };
    const res = await axios.post(
      `${REACT_APP_API_URL}/api/workouts/`,
      data,
      config
    );
    if (res.data) {
      dispatch({
        type: CREATE_WORKOUT_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: CREATE_WORKOUT_FAIL,
      payload: err.response.status,
    });
  }
};

// Get workouts
export const getWorkouts = (clientID) => {
  try {
    return async (dispatch) => {
      const token = await localStorage.getItem("access");
      const config = {
        headers: {
          Authorization: "JWT " + token,
        },
        params: {
          client: clientID,
        },
      };
      const res = await axios.get(`${REACT_APP_API_URL}/api/workouts`, config);
      if (res.data) {
        dispatch({
          type: GET_WORKOUTS,
          payload: res.data,
        });
      }
    };
  } catch (err) {
    // Handle error
  }
};

// Get single workout
export const getSingleWorkout = (clientID, id) => {
  try {
    return async (dispatch) => {
      const token = await localStorage.getItem("access");
      const config = {
        headers: {
          Authorization: "JWT " + token,
        },
        params: {
          client: clientID,
          id: id,
        },
      };
      const res = await axios.get(`${REACT_APP_API_URL}/api/workout/`, config);
      if (res.data) {
        dispatch({
          type: GET_SINGLE_WORKOUT,
          payload: res.data,
        });
      }
    };
  } catch (err) {
    // Handle error
  }
};

// Update workout
export const updateWorkout = (id, data) => async (dispatch) => {
  try {
    const token = await localStorage.getItem("access");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + token,
      },
    };

    const res = await axios.patch(
      `${REACT_APP_API_URL}/api/workouts/${id}/`,
      data,
      config
    );
    if (res.data) {

      dispatch({
        type: UPDATE_WORKOUT,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: CREATE_WORKOUT_FAIL,
      payload: err.response.status,
    });
  }
};

// Complete workout
export const completeWorkout = (id, data) => async (dispatch) => {
  try {
    const token = await localStorage.getItem("access");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + token,
      },
    };
    const res = await axios.patch(
      `${REACT_APP_API_URL}/api/workout/${id}/`,
      data,
      config
    );
    if (res.data) {

      dispatch({
        type: UPDATE_WORKOUT,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: CREATE_WORKOUT_FAIL,
      payload: err.response.status,
    });
  }
};

// Delete workout
export const deleteWorkout = (id) => async (dispatch) => {
  try {
    const token = await localStorage.getItem("access");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + token,
      },
    };
    const res = await axios.delete(
      `${REACT_APP_API_URL}/api/workouts/${id}/`,
      config
    );
    if (res) {
      dispatch({
        type: DELETE_WORKOUT,
        payload: id,
      });
    }
  } catch (err) {
    
    dispatch({
      type: CREATE_WORKOUT_FAIL,
      payload: err.response.status,
    });
  }
};

// Reset workout status
export const resetWorkoutStatus = () => ({
  type: RESET_WORKOUT_STATUS,
});

// Get exercises
export const getExercises = (name = "", tags = [], page_no = 1, clientID="") => {
  try {
    return async (dispatch) => {
      const token = await localStorage.getItem("access");
      const config = {
        headers: {
          // 'Content-Type': 'application/json',
          Authorization: "JWT " + token,
        }
      };
      const tagsString = encodeURIComponent(tags.join(','));
      const res = await axios.get(`${REACT_APP_API_URL}/api/library/?name=${name}&tags=${tagsString}&page=${page_no}&client=${clientID}`, config);
      if (res.data) {
        dispatch({
          type: GET_EXERCISES,
          payload: res.data.results,
          total_count: res.data.count
        });
      }
    };
  } catch (err) {
    // Handle error
  }
};
// Create exercise
export const createExercise = (data) => async (dispatch) => {
  try {
    const token = await localStorage.getItem("access");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + token,
      },
    };
    const res = await axios.post(
      `${REACT_APP_API_URL}/api/exercises/create`,
      data,
      config
    );
    if (res.data) {
      dispatch({
        type: CREATE_EXERCISE_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: CREATE_EXERCISE_FAIL,
      payload: err.response.status,
    });
  }
};

// Create library exercise
export const createLibraryExercise = (formData) => async (dispatch) => {
  try {
    const token = await localStorage.getItem("access");

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "JWT " + token,
      },
    };
    const res = await axios.post(
      `${REACT_APP_API_URL}/api/library/`,
      formData,
      config
    );
    if (res.data) {
      dispatch({
        type: CREATE_LIBRARY_EXERCISE_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: CREATE_LIBRARY_EXERCISE_FAIL,
      payload: err.response.status,
    });
  }
};

export const updateExercise = (id, data) => async (dispatch) => {
  try {
    const token = await localStorage.getItem("access");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + token,
      },
    };
    const res = await axios.patch(
      `${REACT_APP_API_URL}/api/exercises/${id}/`,
      data,
      config
    );
    if (res.data) {
      dispatch({
        type: UPDATE_EXERCISE,
        payload: res.data,
      });
    }
  } catch (err) {
  
  }
};

export const completeExercise = (id, data) => async (dispatch) => {
  try {
    const token = await localStorage.getItem("access");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + token,
      },
    };
    const res = await axios.patch(
      `${REACT_APP_API_URL}/api/exercises/${id}/`,
      data,
      config
    );
    if (res.data) {
      dispatch({
        type: UPDATE_WORKOUT,
        payload: res.data,
      });
    }
  } catch (err) {
  
  }
};


// Reset exercise status
export const resetExerciseStatus = () => ({
  type: RESET_EXERCISE_STATUS,
});

// Create mesocycle
export const createMesocycle = (data) => async (dispatch) => {
  try {
    const token = await localStorage.getItem("access");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + token,
      },
    };

    const res = await axios.post(
      `${REACT_APP_API_URL}/api/mesocycles/`,
      data,
      config
    );

    if (res.data) {
      dispatch({
        type: CREATE_MESOCYCLE_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: CREATE_MESOCYCLE_FAIL,
      payload: err.response.status,
    });
  }
};

// Get mesocycles
export const getMesocycles = (clientID) => {
  try {
    return async (dispatch) => {
      const token = await localStorage.getItem("access");
      const config = {
        headers: {
          Authorization: "JWT " + token,
        },
        params: {
          client: clientID,
        },
      };
      const res = await axios.get(
        `${REACT_APP_API_URL}/api/mesocycles`,
        config
      );
      if (res.data) {
        dispatch({
          type: GET_MEOSCYCLES,
          payload: res.data,
        });
      }
    };
  } catch (err) {
    // Handle error
  }
};

// Get single mesocycle
export const getSingleMesocycle = (clientID, id) => {
  try {
    return async (dispatch) => {
      const token = await localStorage.getItem("access");
      const config = {
        headers: {
          Authorization: "JWT " + token,
        },
        params: {
          client: clientID,
          id: id,
        },
      };
      const res = await axios.get(`${REACT_APP_API_URL}/api/meso/`, config);
      if (res.data) {
        dispatch({
          type: GET_SINGLE_MESOCYCLE,
          payload: res.data,
        });
      }
    };
  } catch (err) {
    // Handle error
  }
};

// Update mesocycle
export const updateMesocycle = (id, data) => async (dispatch) => {
  try {
    const token = await localStorage.getItem("access");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + token,
      },
    };

    const res = await axios.patch(
      `${REACT_APP_API_URL}/api/mesocycles/${id}/`,
      data,
      config
    );
    if (res.data) {
      dispatch({
        type: UPDATE_MESOCYCLE,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: CREATE_MESOCYCLE_FAIL,
      payload: err.response.status,
    });
  }
};

// Copy workout
export const copyWorkout = (id, data) => async (dispatch) => {
  try {
    const token = await localStorage.getItem("access");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + token,
      },
    };
        const res = await axios.post(
      `${REACT_APP_API_URL}/api/workouts/${id}/copy/`,
      data,
      config
    );
    if (res.data) {
    
      dispatch({
        type: COPY_WORKOUT_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: CREATE_WORKOUT_FAIL,
      payload: err.response.status,
    });
  }
};

// Copy Block Week
export const copyBlockWeek = (id, data) => async (dispatch) => {
  try {
    const token = await localStorage.getItem("access");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + token,
      },
    };
    const res = await axios.post(
      `${REACT_APP_API_URL}/api/mesocycles/${id}/copy-week/`,
      data,
      config
    );

  
    if (res.data) {
      dispatch({
        type: COPY_WEEK_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    
    dispatch({
        type: COPY_WEEK_FAIL,
        payload: err.response.status
    });
  }
};

// Delete mesocycle
export const deleteBlock = (id) => async (dispatch) => {
  try {
    const token = await localStorage.getItem("access");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + token,
      },
    };
    const res = await axios.delete(
      `${REACT_APP_API_URL}/api/mesocycles/${id}/`,
      config
    );
    if (res) {
      dispatch({
        type: DELETE_MESOCYCLE,
        payload: id,
      });
    }
  } catch (err) {
    dispatch({
      type: CREATE_MESOCYCLE_FAIL,
      payload: err.response.status,
    });
  }
};

// Reset mesocycle status
export const resetMesocycleStatus = () => ({
  type: RESET_MESOCYCLE_STATUS,
});

// Create program
export const createProgram = (data) => async (dispatch) => {
  try {
    const token = await localStorage.getItem("access");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + token,
      },
    };

    const res = await axios.post(
      `${REACT_APP_API_URL}/api/programs/`,
      data,
      config
    );

    if (res.data) {
    
      dispatch({
        type: CREATE_PROGRAM_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {

    dispatch({
      type: CREATE_PROGRAM_FAIL,
      payload: err.response.status,
    });
  }
};

// Get programs
export const getPrograms = (clientID) => {
  try {
    return async (dispatch) => {
      const token = await localStorage.getItem("access");
      const config = {
        headers: {
          Authorization: "JWT " + token,
        },
        params: {
          client: clientID,
        },
      };
      const res = await axios.get(`${REACT_APP_API_URL}/api/programs`, config);
      if (res.data) {
        dispatch({
          type: GET_PROGRAMS,
          payload: res.data,
        });
      }
    };
  } catch (err) {
    // Handle error
  }
};

// Get single program
export const getSingleProgram = (clientID, id) => {
  try {
    return async (dispatch) => {
      const token = await localStorage.getItem("access");
      const config = {
        headers: {
          Authorization: "JWT " + token,
        },
        params: {
          client: clientID,
          id: id,
        },
      };
      const res = await axios.get(`${REACT_APP_API_URL}/api/program/`, config);
      if (res.data) {
        dispatch({
          type: GET_SINGLE_PROGRAM,
          payload: res.data,
        });
      }
    };
  } catch (err) {
    // Handle error
  }
};

// Update program
export const updateProgram = (id, data) => async (dispatch) => {
  try {
    const token = await localStorage.getItem("access");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + token,
      },
    };

    const res = await axios.patch(
      `${REACT_APP_API_URL}/api/programs/${id}/`,
      data,
      config
    );
    if (res.data) {
      dispatch({
        type: UPDATE_PROGRAM,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: CREATE_PROGRAM_FAIL,
      payload: err.response.status,
    });
  }
};

// Delete program
export const deleteProgram = (id) => async (dispatch) => {
  try {
    const token = await localStorage.getItem("access");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + token,
      },
    };
    const res = await axios.delete(
      `${REACT_APP_API_URL}/api/programs/${id}/`,
      config
    );
    if (res) {
      dispatch({
        type: DELETE_PROGRAM,
        payload: id,
      });
    }
  } catch (err) {
    dispatch({
      type: CREATE_PROGRAM_FAIL,
      payload: err.response.status,
    });
  }
};

// Reset mesocycle status
export const resetProgramStatus = () => ({
  type: RESET_PROGRAM_STATUS,
});
