import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { assignAssessment } from "../redux/actions/clients";
import { massAssignAssessment } from "../redux/actions/trainer_groups";

import { Input, Checkbox, Card, Row, Typography, Col, Select } from "antd";
import { IoCalendarSharp } from "react-icons/io5";
import { css, StyleSheet } from "aphrodite";
import Theme from "../constants/Theme";

import Text from "antd/lib/typography/Text";

import movements from "../constants/movements";
import SelectionCard from "./SelectionCard";
import { Button } from "react-bootstrap";
import { checkStatus } from "../redux/actions/payment";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import CustomSelect from "./common/DropDown";

const Option = { Select };

var freeAngles = {};
var title;

const checkAssLimit = (
  subscriptionStatus,
  compedMembership,
  setReachedLimit,
  user
) => {
  if (
    (subscriptionStatus === false &&
      compedMembership === false &&
      user?.stats?.assessments_current_month < user?.profile?.assessment_cap) ||
    subscriptionStatus === true ||
    compedMembership === true
  ) {
    setReachedLimit(false);
  } else if (
    subscriptionStatus === false &&
    compedMembership === false &&
    user?.stats?.assessments_current_month >= user?.profile?.assessment_cap
  ) {
    setReachedLimit(true);
  }
};

function AssignAssessments({
  setChooseModalVisible,
  clientID,
  groupID,
  closeModal,
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { Theme } = useSelector((state) => state.auth);
  const [cmjSelected, setCmjSelection] = useState(false);
  const [squatSelected, setSquatSelection] = useState(false);
  const [balanceSelected, setBalanceSelection] = useState(false);
  const [postureSelected, setPostureSelection] = useState(false);
  const [lungeSelected, setLungeSelection] = useState(false);
  const [rdlSelected, setRdlSelection] = useState(false);
  const [pushSelected, setPushSelection] = useState(false);
  const [freeSelected, setFreeSelection] = useState(false);
  const [freeModalVisible, setFreeModalVisible] = useState(false);
  const [reachedLimit, setReachedLimit] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [value1, setValue1] = useState("Overhead");
  const [open2, setOpen2] = useState(false);
  const [value2, setValue2] = useState("Single Leg");
  const [open3, setOpen3] = useState(false);
  const [value3, setValue3] = useState("Lateral");

  var submitButtonMuted = true;
  var freeMuted = false;
  var choicesMuted = false;
  var hingeVariation;
  var squatVariation;
  var lungeVariation;

  const choices = [
    { name: "balance", movement: 5, selected: balanceSelected },
    { name: "posture", movement: 0, selected: postureSelected },
    squatVariation,
    hingeVariation,
    lungeVariation,
    { name: "push", movement: 3, selected: pushSelected },
    { name: 'countermovement jump', movement: 14, selected: cmjSelected, value: 1 },
    {
      name: "free record",
      movement: 6,
      selected: freeSelected,
      title: title,
      freeAngles: {
        box_one: "Select",
        box_two: "Select",
        box_three: "Select",
        box_four: "Select",
        box_five: "Select",
        box_six: "Select",
        box_seven: "Select",
        box_eight: "Select",
      },
    },
  ];

  function handleSquatChange(value) {
    setValue1(value);
    squatVariation = value;
  }
  function handleHingeChange(value) {
    setValue2(value);
    hingeVariation = value;
  }
  if (value1 === "Overhead") {
    choices[2] = {
      name: "overhead squat",
      movement: 8,
      selected: squatSelected,
    };
  } else {
    choices[2] = {
      name: "standard squat",
      movement: 7,
      selected: squatSelected,
    };
  }
  function handleChangeLunge(value) {
    setValue3(value);
    lungeVariation = value;
  }
  const filterAndStartAssessment = (choices) => {
    choices[6].freeAngles = freeAngles;
    choices[6].title = title;
    if (value1 === "Overhead") {
      choices[2] = {
        name: "overhead squat",
        movement: 8,
        selected: squatSelected,
      };
    } else {
      choices[2] = {
        name: "standard squat",
        movement: 7,
        selected: squatSelected,
      };
    }
    if (value2 === "Single Leg") {
      choices[3] = {
        name: "rdl",
        movement: 2,
        selected: rdlSelected,
        value: 2,
      };
    } else {
      choices[3] = {
        name: "hinge",
        movement: 12,
        selected: rdlSelected,
        value: 1,
      };
    }
    if (value3 === "Lateral") {
      choices[4] = {
        name: "lateral lunge",
        movement: 10,
        selected: lungeSelected,
        value: 2,
      };
    } else {
      choices[4] = {
        name: "reverse lunge",
        movement: 11,
        selected: lungeSelected,
        value: 2,
      };
    }
    const results = choices.filter((obj) => {
      return obj.selected === true;
    });
    if (clientID != null) {
      dispatch(assignAssessment(clientID, results));
    }
    if (groupID != null) {
      dispatch(massAssignAssessment(groupID, results));
    }
    setChooseModalVisible(false);
  };

  const { user } = useSelector((state) => state.auth);
  const { subscriptionStatus, compedMembership } = useSelector(
    (state) => state.payment
  );

  useEffect(() => {
    dispatch(checkStatus);
    checkAssLimit(subscriptionStatus, compedMembership, setReachedLimit, user);
  }, []);

  useEffect(() => {
    checkAssLimit(subscriptionStatus, compedMembership, setReachedLimit, user);
  }, [subscriptionStatus]);

  if (
    squatSelected ||
    balanceSelected ||
    postureSelected ||
    lungeSelected ||
    rdlSelected ||
    pushSelected
  ) {
    freeMuted = true;
  }

  if (freeSelected) {
    choicesMuted = true;
  }

  return (
    <div>
      {!reachedLimit && (
        <div center style={{ bottom: "1%" }}>
          <h2
            style={{ textAlign: "center", fontSize: "1vw", color: Theme.TEXT }}
          >
            Choose Assessments to Assign{" "}
            {groupID != null ? `to All ${user?.profile?.client_label}s` : ""}
          </h2>
          <Col align="middle">
            <h5
              style={{
                textAlign: "center",
                fontSize: ".7vw",
                width: "70%",
                fontWeight: "400",
                color: Theme.TEXT2
              }}
            >
              {groupID != null
                ? `Assigned assessments will show for all of your ${user?.profile?.client_label.toLowerCase()}' accounts within this group for them to take on their own.`
                : `Assigned assessments show in your ${user?.profile?.client_label.toLowerCase()}'s account for them to take on their own.`}
            </h5>
          </Col>
        </div>
      )}
      {reachedLimit ? (
        <Col
          justify="center"
          align="center"
          style={{ position: "relative", top: "4vw" }}
        >
          <Row align="center">
            <IoCalendarSharp
              style={{
                fontSize: "2vw",
                color: Theme.COLORS.PRIMARY,
                marginBottom: "3%"
              }}
            />
          </Row>
          <Row align="center">
            <Text className={css(styles.movementTitle)}>
              Monthly Assesement Limit Reached
            </Text>
          </Row>
          <Row align="center">
            <Text className={css(styles.clientDescr)}>
              Upgrade your subscription in your account settings to assign another assessment
            </Text>
          </Row>
          
        </Col>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: ".75vw",
            position: "relative"
          }}
        >
          <Row
            align={"center"}
            justify={"center"}
            style={{
              width: "100%",
              marginTop: "5%"
            }}
          >
            <div
              onClick={() => {
                if (!choicesMuted) setCmjSelection(!cmjSelected);
              }}
              style={{ cursor: "pointer" }}
            >
              <SelectionCard
                item={movements[7]}
                muted={choicesMuted}
                selected={cmjSelected}
              />
            </div>

            <Col
              justify="center"
              align="center"
              style={{
                cursor: "pointer",
                marginLeft: "1vw",
                marginRight: "1vw"
              }}
            >
              <Col
                onClick={() => {
                  if (!choicesMuted) setSquatSelection(!squatSelected);
                }}
              >
                <SelectionCard
                  item={movements[1]}
                  muted={choicesMuted}
                  selected={squatSelected}
                />
              </Col>
              <Col style={{ marginTop: ".5vw" }}>
                <CustomSelect
                  defaultValue="Overhead"
                  options={[
                    { value: "Overhead", label: "Overhead" },
                    { value: "Standard", label: "Standard" }
                  ]}
                  onChange={handleSquatChange}
                  width="6vw"
                  placeholder="Select an option"
                  lightModeBackground="#f1f1fb"
                  darkModeBackground="#000"
                />
              </Col>
            </Col>

            <div
              onClick={() => {
                if (!choicesMuted) setBalanceSelection(!balanceSelected);
              }}
              style={{ cursor: "pointer" }}
            >
              <SelectionCard
                item={movements[5]}
                muted={choicesMuted}
                selected={balanceSelected}
              />
            </div>
          </Row>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              width: "100%%",
              marginTop: "2vw",
              marginLeft: "1vw"
            }}
          >
            <Col
              justify="center"
              align="center"
              style={{ cursor: "pointer", marginRight: "1vw" }}
            >
              <Col
                onClick={() => {
                  if (!choicesMuted) setRdlSelection(!rdlSelected);
                }}
              >
                <SelectionCard
                  item={movements[2]}
                  muted={choicesMuted}
                  selected={rdlSelected}
                />
              </Col>
              <Col style={{ marginTop: ".5vw" }}>
                <CustomSelect
                  defaultValue="Single Leg"
                  options={[
                    { value: "Single Leg", label: "Single Leg" },
                    { value: "Bilateral", label: "Bilateral" }
                  ]}
                  onChange={handleHingeChange}
                  width="6vw"
                  placeholder="Select an option"
                  lightModeBackground="#f1f1fb"
                  darkModeBackground="#000"
                />
              </Col>
            </Col>
            <div
              onClick={() => {
                if (!choicesMuted) setPushSelection(!pushSelected);
              }}
              style={{ cursor: "pointer" }}
            >
              <SelectionCard
                item={movements[3]}
                muted={choicesMuted}
                selected={pushSelected}
              />
            </div>
            <Col
              justify="center"
              align="center"
              style={{
                cursor: "pointer",
                marginLeft: "1vw",
                marginRight: "1vw"
              }}
            >
              <Col
                onClick={() => {
                  if (!choicesMuted) setLungeSelection(!lungeSelected);
                }}
              >
                <SelectionCard
                  item={movements[4]}
                  muted={choicesMuted}
                  selected={lungeSelected}
                />
              </Col>
              <Col style={{ marginTop: ".5vw" }}>
                <CustomSelect
                  defaultValue="Lateral"
                  options={[
                    { value: "Lateral", label: "Lateral" },
                    { value: "Reverse", label: "Reverse" }
                  ]}
                  onChange={handleChangeLunge}
                  width="6vw"
                  placeholder="Select an option"
                  lightModeBackground="#f1f1fb"
                  darkModeBackground="#000"
                />
              </Col>
            </Col>
          </div>

          <Button
            style={{
              marginHorizontal: 30,
              marginBottom: 35,
              marginTop: "4vw",
              alignSelf: "center",
              backgroundImage:
                "linear-gradient(45deg, #C04848 0%, #480048 100%)",
              width: "12vw",
              height: "2vw",
              flexDirection: "row",
              elevation: 3,
              color: "white",
              position: "relative",
              fontSize: ".65vw",
              borderRadius: 10,
              borderWidth: 0,
              justifyContent: "center",
              diplay: "flex",
              fontWeight: "bold",
              cursor: "pointer"
            }}
            onClick={() => filterAndStartAssessment(choices)}
          >
            Assign Assessment
          </Button>
        </div>
      )}
    </div>
  );
}

export default AssignAssessments;

const styles = StyleSheet.create({
  movementTitle: {
    alignSelf: "center",
    fontSize: "1.3vw",
    fontWeight: "bold",
    color: Theme.COLORS.BLACK,
    textAlign: "center",
  },
  clientDescr: {
    alignSelf: "center",
    fontSize: ".75vw",
    // fontWeight: "bold",
    color: Theme.COLORS.BLACK,
    textAlign: "center",
  },
});
