import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { login, reset_password } from "../redux/actions/auth";
import "../assets/styles/forms.css";
import runningman from "../assets/images/logowhite.png";
import { Row, Col, Typography, Input, Button, Modal } from "antd";
import AOS from "aos";

import Theme from "../constants/Theme";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import BootstrapInput from "../components/common/Input";

const { Title, Text } = Typography;

const Login = () => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [modalVisible, setModalVisible] = useState(false);
  const { signInStatus, user, isAuthenticated } = useSelector(
    (state) => state.auth
  );
  const { email, password } = formData;
  const history = useHistory();
  const [emailInputValue, setEmailInputValue] = useState("");
  const [messageText, setMessageText] = useState("");
  const [clientMessage, setClientMessage] = useState("");
const [isMobile, setIsMobile] = useState(window.innerWidth < 1000);

  const onSubmit = async () => {
    const email = emailInputValue.toLowerCase();
    dispatch(reset_password(email));
    setTimeout(() => {
      setMessageText(
        "Please check your email for instructions to reset your password"
      );
    }, 750);
  };
  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const submit = (e) => {
    e.preventDefault();
    dispatch(login(email, password));
  };


  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
     if (user?.profile?.account_type === 0) {
       setClientMessage("Clients must use mobile app");
     }
    if (
      (isAuthenticated && user?.profile?.account_type == 1) ||
      user?.profile?.account_type == 2
    ) {
      history.push("/");
    }
   
  }, [isAuthenticated, user, signInStatus]);

  const handleCancel = () => {
    setModalVisible(false);
  };

 const handleWindowResize = () => {
   setIsMobile(window.innerWidth < 996);
 };

  useEffect(() => {
    // Add event listener for window resize
    window.addEventListener("resize", handleWindowResize);
    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <div className="container" style={{ backgroundColor: "transparent" }}>
      <Col
        style={{
          backgroundImage: "linear-gradient(45deg, #000000 0%, #353939 100%)"
        }}
      >
        <Row
          style={{
            width: isMobile ? "100vw" : null,
            zIndex: 999
          }}
        >
          <div
            className="overlay-container"
            style={{
              width: isMobile ? "100vw" : null,
              zIndex: 999,
              backgroundImage: isMobile
                ? "linear-gradient(45deg, #000000 0%, #353939 100%)"
                : null
            }}
          >
            <div
              // className="overlay"
              style={{
                width: isMobile ? "100vw" : null,
                zIndex: 999
              }}
            >
              <div
                style={{
                  marginLeft: isMobile ? null : "15vw",

                  zIndex: 999,
                  backgroundImage: isMobile
                    ? null
                    : "linear-gradient(45deg, #000000 0%, #353939 100%)",
                  borderRadius: 15
                }}
                className="overlay-panel overlay-right"
              >
                <img
                  style={{ height: isMobile ? "5vw" : "2vw", marginTop: "2vw" }}
                  className={`fade-in-slide-up-element ${
                    true ? "fade-in-slide-up-animation" : ""
                  }`}
                  src={runningman}
                  alt=""
                />

                <form
                  style={{ background: "transparent", zIndex: 999 }}
                  onSubmit={(e) => submit(e)}
                  className={`fade-in-slide-up-element ${
                    true ? "fade-in-slide-up-animation" : ""
                  }`}
                >
                  <div className="form-fields">
                    <BootstrapInput
                      type="email"
                      placeholder="Email"
                      name="email"
                      width={isMobile ? 300 : "15vw"}
                      value={email}
                      style={{
                        marginTop: "5%",
                        borderRadius: 10,
                        backgroundColor: "#000",
                        borderWidth: 5,
                        fontSize: 15
                      }}
                      onChange={(e) =>
                        setFormData({ ...formData, email: e.target.value })
                      }
                      required
                    />
                    <BootstrapInput
                      type="password"
                      placeholder="Password"
                      name="password"
                      value={password}
                      style={{
                        marginTop: "5%",
                        borderRadius: 10,
                        backgroundColor: "#000"
                      }}
                      width={isMobile ? 300 : "15vw"}
                      onChange={(e) =>
                        setFormData({ ...formData, password: e.target.value })
                      }
                      minLength="8"
                      required
                    />
                    <div
                      style={{
                        position: "relative",
                        marginTop: 35,
                        flexDirection: "column"
                      }}
                    >
                      <Button
                        style={{ cursor: "pointer", fontWeight: "bold" }}
                        className="sign-in-button"
                        type="submit"
                        onClick={(e) => submit(e)}
                      >
                        Let's Go!
                      </Button>
                      {signInStatus === 401 && (
                        <p style={{ color: "red", marginTop: "5%" }}>
                          Invalid Username or Password
                        </p>
                      )}
                      {clientMessage && (
                        <p
                          style={{
                            color: "red",
                            fontSize: isMobile ? "5vw" : ".75vw"
                          }}
                        >
                          Client or Personal accounts must use mobile app -{" "}
                          <a
                            href="https://demotu.page.link/anamalium"
                            style={{
                              color: "red",
                              fontSize: isMobile ? "5vw" : ".75vw",
                              textDecorationLine: "underline"
                            }}
                            className="font-bold"
                          >
                            Download Here{" "}
                          </a>
                        </p>
                      )}
                    </div>
                    <div
                      style={{
                        position: "relative",
                        marginTop: 35,
                        flexDirection: "column"
                      }}
                    >
                      <a
                        style={{ color: "White" }}
                        onClick={() => setModalVisible(true)}
                      >
                        Forgot your password?
                      </a>
                    </div>
                    <div
                      style={{
                        position: "relative",
                        top: "1.5vw"
                      }}
                    >
                      <p
                        style={{ fontSize: isMobile ? "4vw" : ".7vw" }}
                        className="font-semibold text-muted text-center"
                      >
                        Don't have an account?{" "}
                        <Link
                          to="/sign-up"
                          className="font-bold"
                          style={{ color: "white" }}
                        >
                          Sign Up
                        </Link>
                      </p>
                    </div>
                  </div>
                </form>
                <Modal
                  open={modalVisible}
                  onCancel={handleCancel}
                  width={isMobile ? 300 : "32vw"}
                  footer={[null]}
                >
                  <Row justify="center" align="center">
                    <Col justify="center" align="center" span={24}>
                      <div>
                        <Title level={3}>Forgot Your Password?</Title>
                        <div
                          style={{
                            marginTop: "1%",
                            marginBottom: "5%",
                            fontSize: "13px",
                            textAlign: "center"
                          }}
                        >
                          Enter your email and we'll send you a link to reset it
                        </div>
                        <div>
                          <Input
                            // prefix={<Icon type="mail" />}
                            style={{
                              width: isMobile ? 200 : "15vw",
                              marginBottom: "2vw"
                            }}
                            width={isMobile ? 200 : "15vw"}
                            type="email"
                            placeholder="Email"
                            name="email"
                            value={emailInputValue}
                            onChange={(e) => setEmailInputValue(e.target.value)}
                          />
                        </div>
                        <Button
                          style={{ cursor: "pointer", fontWeight: "bold" }}
                          className="sign-in-button"
                          type="submit"
                          onClick={onSubmit}
                        >
                          Reset Password
                        </Button>
                        <div
                          style={{
                            marginTop: "5%",
                            fontSize: ".65vw",
                            fontWeight: "bold",
                            textAlign: "center",
                            color: Theme.COLORS.PRIMARY,
                            width: "75%"
                          }}
                        >
                          {messageText}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Modal>
              </div>
            </div>
          </div>
        </Row>
        {!isMobile && (
          <video
            autoPlay
            loop
            muted
            style={{
              position: "fixed",
              // left: "50%",

              height: "100vh",
              width: "100vw",
              objectFit: "cover",
              zIndex: 1
            }}
            // className={`fade-in-slide-up-element ${
            //   true ? "fade-in-slide-up-animation" : ""
            // }`}
          >
            <source
              src={"https://demotu-web.s3.us-west-2.amazonaws.com/2.mp4"}
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        )}
      </Col>
    </div>
  );
};

export default Login;
