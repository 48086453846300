import React, { useState } from "react";
import { Card, Col, Row, Typography, Spin, Button, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { IoInformationCircleOutline } from "react-icons/io5";
import ResultsSectionTitle from "./ResultsSectionTitle";
import sections from "../../constants/sections";
import footwidth from "../../assets/images/footwidth.png";
import footsym from "../../assets/images/footsym.png";
import footcontact from "../../assets/images/footcontact.png";
import ScoreCircle from "../chart/ScoreCircle";
import ProgressProvider from "../chart/ProgressProvider";
import { CircularProgressbar } from "react-circular-progressbar";
import landing_mechanics from "../../constants/landing_mechanics";
const { Text } = Typography;

const CMJResults = ({ item, cmj }) => {
  const { assessment } = useSelector((state) => state.assessments);
  const { user, Theme } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [modal, setModalVisible] = useState(false);
  const [landingModal, setLandingModalVisible] = useState(false);

  const [videoModal, setVideoModal] = useState(false);
  const styles = createStyles(Theme);
  const passOrFail = (item) => {
    return item === true ? "Pass" : "Fail";
  };

  return (
    <Col
      align="middle"
      justify="center"
      style={{
        alignItems: "center",
        display: "flex",
        top: "5%",
        position: "relative",
        left: ".5vw"
      }}
    >
      <Col align="center" justify="center">
        <Row
          style={{ alignSelf: "center", position: "relative" }}
          gutter={[16, 16]}
        >
          <Col align="center">
            <Col
              // bordered={true}
              align="center"
              style={{
                zIndex: 100,
                flex: 1,
                position: "relative",
                width: "17vw",
                display: "flex",
                borderRadius: 10,
                backgroundColor: Theme.CARD,
                boxShadow: "2px 6px 6px 6px rgba(0, 0, 0, 0.025)"
                // marginBottom: "10%",
              }}
              className="criclebox h-full"
            >
              <Col
                align="start"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  top: "3vw",
                  position: "relative",
                  width: "20vw"

                  // minHeight: "13vw",
                  // height: 'auto'
                }}
              >
                <Row
                  align="center"
                  style={{ position: "relative", width: "20vw" }}
                >
                  <Col
                    align={"start"}
                    style={{ position: "relative", right: "10%" }}
                  >
                    <Text style={styles.movementTitle}>COUNTER</Text>
                    <div style={{ bottom: ".15vw", position: "relative" }}>
                      <Text style={styles.movementTitle}>MOVEMENT</Text>
                    </div>
                    <div style={{ bottom: ".25vw", position: "relative" }}>
                      <Text style={styles.movementTitle}>JUMP</Text>
                      <IoInformationCircleOutline
                        onClick={() => setModalVisible(true)}
                        color={Theme.PRIMARY}
                        size={".8vw"}
                        style={{
                          alignSelf: "center",
                          left: "8%",
                          // top: ".2vw",
                          zIndex: 80,
                          cursor: "pointer",
                          position: "relative"
                        }}
                      />
                    </div>
                    <Modal
                      open={modal}
                      onCancel={() => setModalVisible(false)}
                      footer={null}
                      bodyStyle={{ height: "50vw", padding: "1vw" }}
                      width={"40vw"}
                      className={
                        Theme.BACKGROUND === "#000"
                          ? "customAntdModalDark"
                          : "customAntdModalLight"
                      }
                    >
                      <Row justify="center">
                        <Col span={24}>
                          <h1
                            style={{
                              position: "relative",
                              bottom: "1vw",
                              color: Theme.TEXT
                            }}
                          >
                            Overall Score
                          </h1>
                          <Text
                            style={{
                              fontSize: "1vw",
                              position: "relative",
                              bottom: "1vw",
                              color: Theme.TEXT2
                            }}
                          >
                            The overall score represents the average of two key
                            components: the jump height score and the landing
                            mechanics score.
                          </Text>
                          <Col span={24}>
                            <h1
                              style={{
                                position: "relative",
                                bottom: "1vw",
                                color: Theme.TEXT
                              }}
                            >
                              Jump Height
                            </h1>
                            <Text
                              style={{
                                fontSize: "1vw",
                                position: "relative",
                                bottom: "1vw",
                                color: Theme.TEXT2
                              }}
                            >
                              The jump height score is calculated by comparing
                              your {user?.profile?.client_label.toLowerCase()}'s
                              jump height to established standards based on
                              their gender and age group. This comparison
                              ensures that their performance is evaluated
                              against relevant benchmarks. If no Age or Gender
                              is provided, no score will show. Here are the
                              ranges for gender and age:
                            </Text>
                          </Col>
                          <Row
                            align={"middle"}
                            justify={"center"}
                            style={styles.paragraph}
                          >
                            <Col style={{ marginRight: "5vw" }}>
                              <Col>
                                <Text
                                  style={{
                                    fontSize: ".95vw",
                                    fontWeight: "bold",
                                    color: Theme.TEXT
                                  }}
                                >
                                  Male
                                </Text>
                              </Col>

                              <Col>
                                <Text style={styles.paragraphHeight}>
                                  Age {"<= "}9 -- 14 inches (35 cm)
                                </Text>
                              </Col>

                              <Col>
                                <Text style={styles.paragraphHeight}>
                                  Age 10-13 -- 18 inches (46 cm)
                                </Text>
                              </Col>

                              <Col>
                                <Text style={styles.paragraphHeight}>
                                  Age 14-17 -- 22 inches (56 cm)
                                </Text>
                              </Col>

                              <Col>
                                <Text style={styles.paragraphHeight}>
                                  Age 18-25 -- 26 inches (66 cm)
                                </Text>
                              </Col>

                              <Col>
                                <Text style={styles.paragraphHeight}>
                                  Age 26-35 -- 24 inches (61 cm)
                                </Text>
                              </Col>

                              <Col>
                                <Text style={styles.paragraphHeight}>
                                  Age 36-45 -- 22 inches (56 cm)
                                </Text>
                              </Col>

                              <Col>
                                <Text style={styles.paragraphHeight}>
                                  Age 46-55 -- 20 inches (51 cm)
                                </Text>
                              </Col>

                              <Col>
                                <Text style={styles.paragraphHeight}>
                                  Age 56-65 -- 18 inches (46 cm)
                                </Text>
                              </Col>
                            </Col>
                            
                            <Col>
                              <Col>
                                <Text
                                  style={{
                                    fontSize: ".95vw",
                                    fontWeight: "bold",
                                    color: Theme.TEXT
                                  }}
                                >
                                  Female
                                </Text>
                              </Col>
                              <Col>
                                <Text style={styles.paragraphHeight}>
                                  Age {"<= "}9 -- 12 inches (30 cm)
                                </Text>
                              </Col>
                              <Col>
                                <Text style={styles.paragraphHeight}>
                                  Age 10-13 -- 16 inches (41 cm)
                                </Text>
                              </Col>
                              <Col>
                                <Text style={styles.paragraphHeight}>
                                  Age 14-17 -- 20 inches (51 cm)
                                </Text>
                              </Col>
                              <Col>
                                <Text style={styles.paragraphHeight}>
                                  Age 18-25 -- 22 inches (56 cm)
                                </Text>
                              </Col>
                              <Col>
                                <Text style={styles.paragraphHeight}>
                                  Age 26-35 -- 20 inches (51 cm)
                                </Text>
                              </Col>
                              <Col>
                                <Text style={styles.paragraphHeight}>
                                  Age 36-45 -- 18 inches (46 cm)
                                </Text>
                              </Col>
                              <Col>
                                <Text style={styles.paragraphHeight}>
                                  Age 46-55 -- 16 inches (41 cm)
                                </Text>
                              </Col>
                              <Col>
                                <Text style={styles.paragraphHeight}>
                                  Age 56-65 -- 14 inches (36 cm)
                                </Text>
                              </Col>
                            </Col>
                            <Col>
                            <h1
                            style={{
                              position: "relative",
                              color: Theme.TEXT
                            }}
                          >
                            Takeoff Depth
                          </h1>
                          <Text
                            style={{
                              fontSize: "1vw",
                              position: "relative",
                              bottom: "1vw",
                              color: Theme.TEXT2
                            }}
                          >
                            The takeoff depth is calculated as the percentage of descent from the starting position before takeoff. 
                          </Text>
                          </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Modal>
                  </Col>
                  <Col
                    align="middle"
                    style={{
                      position: "relative",
                      left: "10%",
                      bottom: ".5vw"
                    }}
                  >
                    <ScoreCircle
                      score={Math.round(cmj.cmj_overall_score)}
                      height="4vw"
                    />
                    <div>
                      <Text style={styles.messageText}>Overall</Text>
                    </div>
                  </Col>
                </Row>

                <Col
                  name="scores"
                  align="center"
                  justify="center"
                  style={{
                    marginBottom: "7vw",
                    position: "relative",
                    top: "1vw"
                    // width: "20vw",
                  }}
                >
                  <Col
                    align="middle"
                    justify={"center"}
                    style={{ marginTop: "3vw" }}
                  >
                    <Col
                      align="middle"
                      justify={"center"}
                      style={{
                        position: "relative",

                        bottom: "1vw"
                      }}
                    >
                      {cmj.max_jump_score != -1 ? (
                        <ScoreCircle
                          score={Math.round(cmj.max_jump_score)}
                          height="5vw"
                        />
                      ) : (
                        <ProgressProvider valueStart={0} valueEnd={0}>
                          {(value) => (
                            <CircularProgressbar
                              styles={{
                                root: { width: "100%", height: "4.5vw" },
                                path: {
                                  stroke: "gray",
                                  strokeLinecap: "butt",
                                  transition: "stroke-dashoffset 0.5s ease 0s"
                                },
                                trail: {
                                  stroke: Theme.INPUT,
                                  strokeLinecap: "butt"
                                },
                                text: {
                                  fill: Theme.TEXT,
                                  fontSize: "1.5vw"
                                }
                              }}
                              value={value}
                              text={"N/A"}
                            />
                          )}
                        </ProgressProvider>
                      )}
                    </Col>
                    <Col
                      align="middle"
                      justify={"center"}
                      style={{
                        position: "relative",
                        top: ".25vw"
                      }}
                    >
                      <div>
                        <Text style={styles.mainMessageText}>Jump Height</Text>
                      </div>
                      <div>
                        {user?.profile?.measurement === "Imperial" ? (
                          <Text style={styles.angleText}>
                            {cmj.max_jump_height_inches.toFixed(2)}"
                          </Text>
                        ) : (
                          <Text style={styles.angleText}>
                            {(cmj.max_jump_height_inches * 2.54).toFixed(2)}cm
                          </Text>
                        )}
                      </div>
                    </Col>
                    <Col
                      align="middle"
                      justify={"center"}
                      style={{
                        position: "relative",
                        marginTop: "2vw"
                        // left: "20%",
                      }}
                    >
                      <div>
                        <Text style={styles.mainMessageText}>Flight Time</Text>
                      </div>
                      <div>
                        <Text style={styles.angleText}>
                          {cmj.flight_time_seconds.toFixed(2)}s
                        </Text>
                      </div>
                    </Col>
                    {(cmj.depth_percentage &&
                    <Col
                      align="middle"
                      justify={"center"}
                      style={{
                        position: "relative",
                        marginTop: "2vw"
                        // left: "20%",
                      }}
                    >
                      <div>
                        <Text style={styles.mainMessageText}>Takeoff Depth</Text>
                      </div>
                      <div>
                        <Text style={styles.angleText}>
                          {cmj.depth_percentage.toFixed(2)}%
                        </Text>
                      </div>
                    </Col>
                    )}

                  </Col>
                </Col>
                <Button
                  onClick={() => setVideoModal(true)}
                  style={{
                    color: "white",
                    borderWidth: 0,
                    borderRadius: 12,
                    // height: "1.5vw",
                    width: "10vw",
                    fontWeight: "bold",
                    fontSize: ".85vw",
                    cursor: "pointer",

                    background: "black"
                  }}
                >
                  Replay
                </Button>
              </Col>
            </Col>
          </Col>

          <Col>
            <Card
              style={{
                zIndex: 100,
                flex: 1,
                position: "relative",
                width: "22vw",
                borderRadius: 10,
                background: Theme.CARD,
                borderWidth: 0
              }}
              className="criclebox h-full"
            >
              <Col style={{ marginTop: "1vw", paddingBottom: "1vw" }}>
                <ResultsSectionTitle section={sections[12]} />
                <Col align={"middle"} justify={"center"}>
                  <Row
                    align={"middle"}
                    justify={"center"}
                    style={{ marginTop: "1vw" }}
                  >
                    <Col align="start">
                      <Text style={styles.rawMessageText}>&nbsp;</Text>
                      <Col style={{ marginTop: ".25vw" }}>
                        <Text style={styles.rawMessageText}>Knee (L)</Text>
                      </Col>
                      <Col style={{ marginTop: ".25vw" }}>
                        <Text style={styles.rawMessageText}>Knee (R)</Text>
                      </Col>
                      <Col style={{ marginTop: ".25vw" }}>
                        <Text style={styles.rawMessageText}>Hip (L)</Text>
                      </Col>
                      <Col style={{ marginTop: ".25vw" }}>
                        <Text style={styles.rawMessageText}>Hip (R)</Text>
                      </Col>
                      {/* <Col style={{ marginTop: ".25vw" }}>
                        <Text style={styles.rawMessageText}>Ankle (L)</Text>
                      </Col>
                      <Col style={{ marginTop: ".25vw" }}>
                        <Text style={styles.rawMessageText}>Ankle (R)</Text>
                      </Col> */}
                      <Col style={{ marginTop: ".25vw" }}>
                        <Text style={styles.rawMessageText}>Trunk Flexion</Text>
                      </Col>
                      <Col style={{ marginTop: ".25vw" }}>
                        <Text style={styles.rawMessageText}>Trunk Lateral</Text>
                      </Col>
                    </Col>
                    <Col align="center" style={{ marginLeft: "3vw", marginTop:".75vw" }}>
                      <Text style={styles.rawMessageText}>Initial Contact</Text>
                      <Col style={{ marginTop: ".25vw" }}>
                        <Text style={styles.rawResultText}>
                          {Math.round(cmj.initial_contact.l_knee_flexion)}°
                        </Text>
                      </Col>
                      <Col style={{ marginTop: ".25vw" }}>
                        <Text style={styles.rawResultText}>
                          {Math.round(cmj.initial_contact.r_knee_flexion)}°
                        </Text>
                      </Col>
                      <Col style={{ marginTop: ".25vw" }}>
                        <Text style={styles.rawResultText}>
                          {Math.round(cmj.initial_contact.l_hip_flexion)}°
                        </Text>
                      </Col>
                      <Col style={{ marginTop: ".25vw" }}>
                        <Text style={styles.rawResultText}>
                          {Math.round(cmj.initial_contact.r_hip_flexion)}°
                        </Text>
                      </Col>
                      {/* <Col style={{ marginTop: ".25vw" }}>
                        <Text style={styles.rawResultText}>
                          {Math.round(cmj.initial_contact.l_ankle)}°
                        </Text>
                      </Col>
                      <Col style={{ marginTop: ".25vw" }}>
                        <Text style={styles.rawResultText}>
                          {Math.round(cmj.initial_contact.r_ankle)}°
                        </Text>
                      </Col> */}
                      <Col style={{ marginTop: ".25vw" }}>
                        <Text style={styles.rawResultText}>
                          {Math.round(cmj.initial_contact.trunk_flexion)}°
                        </Text>
                      </Col>
                      <Col style={{ marginTop: ".25vw" }}>
                        <Text style={styles.rawResultText}>
                          {Math.round(
                            cmj.initial_contact.trunk_lateral_flexion
                          )}
                          °
                        </Text>
                      </Col>
                    </Col>
                  </Row>
                  <Row
                    align={"middle"}
                    justify={"center"}
                    style={{ marginTop: "1vw" }}
                  >
                    <Col align="start">
                      <Text style={styles.rawMessageText}>&nbsp;</Text>
                      <Col style={{ marginTop: ".25vw" }}>
                        <Text style={styles.rawMessageText}>Knee (L)</Text>
                      </Col>
                      <Col style={{ marginTop: ".25vw" }}>
                        <Text style={styles.rawMessageText}>Knee (R)</Text>
                      </Col>
                      <Col style={{ marginTop: ".25vw" }}>
                        <Text style={styles.rawMessageText}>Hip (L)</Text>
                      </Col>
                      <Col style={{ marginTop: ".25vw" }}>
                        <Text style={styles.rawMessageText}>Hip (R)</Text>
                      </Col>
                      {/* <Col style={{ marginTop: ".25vw" }}>
                        <Text style={styles.rawMessageText}>Ankle (L)</Text>
                      </Col>
                      <Col style={{ marginTop: ".25vw" }}>
                        <Text style={styles.rawMessageText}>Ankle (R)</Text>
                      </Col> */}
                      <Col style={{ marginTop: ".25vw" }}>
                        <Text style={styles.rawMessageText}>Trunk Flexion</Text>
                      </Col>
                      <Col style={{ marginTop: ".25vw" }}>
                        <Text style={styles.rawMessageText}>Trunk Lateral</Text>
                      </Col>
                    </Col>
                    <Col align="center" style={{ marginLeft: "3vw", marginTop:"1vw" }}>
                      <Text style={styles.rawMessageText}>Max Knee Flex</Text>
                      <Col style={{ marginTop: ".25vw" }}>
                        <Text style={styles.rawResultText}>
                          {Math.round(cmj.max_flexion.l_knee_flexion)}°
                        </Text>
                      </Col>
                      <Col style={{ marginTop: ".25vw" }}>
                        <Text style={styles.rawResultText}>
                          {Math.round(cmj.max_flexion.r_knee_flexion)}°
                        </Text>
                      </Col>
                      <Col style={{ marginTop: ".25vw" }}>
                        <Text style={styles.rawResultText}>
                          {Math.round(cmj.max_flexion.l_hip_flexion)}°
                        </Text>
                      </Col>
                      <Col style={{ marginTop: ".25vw" }}>
                        <Text style={styles.rawResultText}>
                          {Math.round(cmj.max_flexion.r_hip_flexion)}°
                        </Text>
                      </Col>
                      {/* <Col style={{ marginTop: ".25vw" }}>
                        <Text style={styles.rawResultText}>
                          {Math.round(cmj.max_flexion.l_ankle)}°
                        </Text>
                      </Col>
                      <Col style={{ marginTop: ".25vw" }}>
                        <Text style={styles.rawResultText}>
                          {Math.round(cmj.max_flexion.r_ankle)}°
                        </Text>
                      </Col> */}
                      <Col style={{ marginTop: ".25vw" }}>
                        <Text style={styles.rawResultText}>
                          {Math.round(cmj.max_flexion.trunk_flexion)}°
                        </Text>
                      </Col>
                      <Col style={{ marginTop: ".25vw" }}>
                        <Text style={styles.rawResultText}>
                          {Math.round(cmj.max_flexion.trunk_lateral_flexion)}°
                        </Text>
                      </Col>
                    </Col>
                  </Row>
                </Col>
              </Col>
            </Card>
          </Col>
          <Col>
            <Card
              style={{
                zIndex: 100,
                // flex: 1,
                position: "relative",
                width: "31vw",
                borderRadius: 10,
                background: Theme.CARD,
                borderWidth: 0
              }}
              align={"middle"}
              justify={"center"}
              className="criclebox h-full"
            >
              <Col
                align="middle"
                style={{
                  position: "absolute",
                  right: "2.5%",
                  top: "1vw"
                }}
              >
                <ScoreCircle score={Math.round(cmj.LESS.score)} height="4vw" />
              </Col>
              <Col style={{ marginTop: "1vw" }}></Col>
              <Col>
                <Text
                  style={{
                    marginTop: 12,
                    fontSize: "1vw",
                    fontWeight: "600",
                    alignSelf: "center",
                    position: "relative",
                    color: Theme.TEXT
                  }}
                >
                  LANDING MECHANICS
                </Text>
                <Col>
                  <IoInformationCircleOutline
                    onClick={() => setLandingModalVisible(true)}
                    color={Theme.PRIMARY}
                    size={".8vw"}
                    style={{
                      alignSelf: "center",

                      // top: ".2vw",
                      zIndex: 80,
                      cursor: "pointer",
                      position: "relative"
                    }}
                  />
                </Col>
              </Col>
              <Row
                align={"center"}
                justify={"center"}
                style={{ position: "relative", marginTop: "1vw" }}
              >
                {/* left column */}
                <Col align={"center"} justify={"center"}>
                  <Col column center middle style={{}}>
                    <img
                      src={footwidth}
                      style={styles.horizontalImage}
                      resizeMode="contain"
                    />
                    <Col>
                      <Text style={styles.mechanicsTitle}>Foot Width</Text>
                    </Col>
                    <Col style={{ bottom: ".25vw", position: "relative" }}>
                      <Text style={styles.mechanicsTitle}>Initial Contact</Text>
                    </Col>
                    <Col
                      column
                      center
                      middle
                      style={{ bottom: ".25vw", position: "relative" }}
                    >
                      <Text
                        style={{
                          color: cmj.LESS.stance_width
                            ? Theme.GRAD6
                            : Theme.RED,
                          fontWeight: "bold",
                          fontSize: "1vw"
                        }}
                      >
                        {passOrFail(cmj.LESS.stance_width)}
                      </Text>
                    </Col>
                    <Col style={{ bottom: ".25vw", position: "relative" }}>
                      <Text style={{ color: Theme.TEXT2 }}>
                        ({cmj.LESS.stance_description})
                      </Text>
                    </Col>
                  </Col>
                  <Col column center middle style={{ marginTop: ".5vw" }}>
                    <img
                      src={require("../../assets/images/knee.png")}
                      style={styles.horizontalImage}
                      resizeMode="contain"
                    />
                    <Col>
                      <Text style={styles.mechanicsTitle}>Knee Flexion</Text>
                    </Col>
                    <Col style={{ bottom: ".25vw", position: "relative" }}>
                      <Text style={styles.mechanicsTitle}>Initial Contact</Text>
                    </Col>
                    <Col
                      column
                      center
                      middle
                      style={{ bottom: ".25vw", position: "relative" }}
                    >
                      <Text
                        style={{
                          color: cmj.LESS.knee_flexion_IC
                            ? Theme.GRAD6
                            : Theme.RED,
                          fontWeight: "bold",
                          fontSize: "1vw"
                        }}
                      >
                        {passOrFail(cmj.LESS.knee_flexion_IC)}
                      </Text>
                    </Col>
                    <Col style={{ bottom: ".25vw", position: "relative" }}>
                      {cmj.LESS.knee_flexion_IC ? (
                        <Text style={{ color: Theme.TEXT2 }}>(Flexed)</Text>
                      ) : (
                        <Text style={{ color: Theme.TEXT2 }}>(Extended)</Text>
                      )}
                    </Col>
                  </Col>
                  <Col column center middle style={{ marginTop: ".5vw" }}>
                    <img
                      src={require("../../assets/images/trunklatflex.png")}
                      style={styles.horizontalImage}
                      resizeMode="contain"
                    />
                    <Col>
                      <Text style={styles.mechanicsTitle}>Trunk Lateral</Text>
                    </Col>
                    <Col style={{ bottom: ".25vw", position: "relative" }}>
                      <Text style={styles.mechanicsTitle}>Initial Contact</Text>
                    </Col>
                    <Col
                      column
                      center
                      middle
                      style={{ bottom: ".25vw", position: "relative" }}
                    >
                      <Text
                        style={{
                          color: cmj.LESS.trunk_lateral_flexion_IC
                            ? Theme.GRAD6
                            : Theme.RED,
                          fontWeight: "bold",
                          fontSize: "1vw"
                        }}
                      >
                        {passOrFail(cmj.LESS.trunk_lateral_flexion_IC)}
                      </Text>
                    </Col>
                    <Col style={{ bottom: ".25vw", position: "relative" }}>
                      {cmj.LESS.trunk_lateral_flexion_IC ? (
                        <Text style={{ color: Theme.TEXT2 }}>(Absent)</Text>
                      ) : (
                        <Text style={{ color: Theme.TEXT2 }}>(Present)</Text>
                      )}
                    </Col>
                  </Col>
                  <Col
                    column
                    center
                    middle
                    style={{ marginTop: ".5vw", left: "4vw" }}
                  >
                    <img
                      src={require("../../assets/images/maxtrunkflex.png")}
                      style={styles.horizontalImage}
                      resizeMode="contain"
                    />
                    <Col>
                      <Text style={styles.mechanicsTitle}>
                        Trunk Flexion at
                      </Text>
                    </Col>
                    <Col style={{ bottom: ".25vw", position: "relative" }}>
                      <Text style={styles.mechanicsTitle}>
                        Max Knee Flexion
                      </Text>
                    </Col>
                    <Col
                      column
                      center
                      middle
                      style={{ bottom: ".25vw", position: "relative" }}
                    >
                      <Text
                        style={{
                          color: cmj.LESS.trunk_flexion_max
                            ? Theme.GRAD6
                            : Theme.RED,
                          fontWeight: "bold",
                          fontSize: "1vw"
                        }}
                      >
                        {passOrFail(cmj.LESS.trunk_flexion_max)}
                      </Text>
                    </Col>
                    <Col style={{ bottom: ".25vw", position: "relative" }}>
                      <Text style={{ color: Theme.TEXT2 }}>
                        ({Math.round(cmj.LESS.trunk_flexion_displacement)}°)
                      </Text>
                    </Col>
                  </Col>
                </Col>
                {/* middle column */}
                <Col style={{ marginLeft: "3vw", marginRight: "3vw" }}>
                  <Col column center middle style={{}}>
                    <img
                      src={footsym}
                      style={styles.horizontalImage}
                      resizeMode="contain"
                    />
                    <Col>
                      <Text style={styles.mechanicsTitle}>Foot Symmetry</Text>
                    </Col>
                    <Col style={{ bottom: ".25vw", position: "relative" }}>
                      <Text style={styles.mechanicsTitle}>Initial Contact</Text>
                    </Col>
                    <Col
                      column
                      center
                      middle
                      style={{ bottom: ".25vw", position: "relative" }}
                    >
                      <Text
                        style={{
                          color: cmj.LESS.stance_symmetrical
                            ? Theme.GRAD6
                            : Theme.RED,
                          fontWeight: "bold",
                          fontSize: "1vw"
                        }}
                      >
                        {passOrFail(cmj.LESS.stance_symmetrical)}
                      </Text>
                    </Col>
                    <Col style={{ bottom: ".25vw", position: "relative" }}>
                      {cmj.LESS.stance_symmetrical ? (
                        <Text style={{ color: Theme.TEXT2 }}>
                          (Symmetrical)
                        </Text>
                      ) : (
                        <Text style={{ color: Theme.TEXT2 }}>
                          (Asymmetrical)
                        </Text>
                      )}
                    </Col>
                  </Col>
                  <Col column center middle style={{ marginTop: ".5vw" }}>
                    <img
                      src={require("../../assets/images/hippie.png")}
                      style={styles.horizontalImage}
                      resizeMode="contain"
                    />
                    <Col>
                      <Text style={styles.mechanicsTitle}>Hip Flexion</Text>
                    </Col>
                    <Col style={{ bottom: ".25vw", position: "relative" }}>
                      <Text style={styles.mechanicsTitle}>Initial Contact</Text>
                    </Col>
                    <Col
                      column
                      center
                      middle
                      style={{ bottom: ".25vw", position: "relative" }}
                    >
                      <Text
                        style={{
                          color: cmj.LESS.hip_flexion_IC
                            ? Theme.GRAD6
                            : Theme.RED,
                          fontWeight: "bold",
                          fontSize: "1vw"
                        }}
                      >
                        {passOrFail(cmj.LESS.hip_flexion_IC)}
                      </Text>
                    </Col>
                    <Col style={{ bottom: ".25vw", position: "relative" }}>
                      {cmj.LESS.hip_flexion_IC ? (
                        <Text style={{ color: Theme.TEXT2 }}>(Flexed)</Text>
                      ) : (
                        <Text style={{ color: Theme.TEXT2 }}>(Extended)</Text>
                      )}
                    </Col>
                  </Col>
                  <Col column center middle style={{ marginTop: ".5vw" }}>
                    <img
                      src={require("../../assets/images/kneemax.png")}
                      style={styles.horizontalImage}
                      resizeMode="contain"
                    />
                    <Col>
                      <Text style={styles.mechanicsTitle}>Max Knee</Text>
                    </Col>
                    <Col style={{ bottom: ".25vw", position: "relative" }}>
                      <Text style={styles.mechanicsTitle}>Flexion</Text>
                    </Col>
                    <Col
                      column
                      center
                      middle
                      style={{ bottom: ".25vw", position: "relative" }}
                    >
                      <Text
                        style={{
                          color: cmj.LESS.knee_flexion_max
                            ? Theme.GRAD6
                            : Theme.RED,
                          fontWeight: "bold",
                          fontSize: "1vw"
                        }}
                      >
                        {passOrFail(cmj.LESS.knee_flexion_max)}
                      </Text>
                    </Col>
                    <Col style={{ bottom: ".25vw", position: "relative" }}>
                      <Text style={{ color: Theme.TEXT2 }}>
                        ({Math.round(cmj.LESS.knee_flexion_displacement)}°)
                      </Text>
                    </Col>
                  </Col>
                </Col>

                {/* right column */}
                <Col>
                  <Col column center middle style={{}}>
                    <img
                      src={footcontact}
                      style={styles.horizontalImage}
                      resizeMode="contain"
                    />
                    <Col>
                      <Text style={styles.mechanicsTitle}>Foot Contact</Text>
                    </Col>
                    <Col style={{ bottom: ".25vw", position: "relative" }}>
                      <Text style={styles.mechanicsTitle}>Pattern</Text>
                    </Col>
                    <Col
                      column
                      center
                      middle
                      style={{ bottom: ".25vw", position: "relative" }}
                    >
                      <Text
                        style={{
                          color: cmj.LESS.toe_to_heel ? Theme.GRAD6 : Theme.RED,
                          fontWeight: "bold",
                          fontSize: "1vw"
                        }}
                      >
                        {passOrFail(cmj.LESS.toe_to_heel)}
                      </Text>
                    </Col>
                    <Col style={{ bottom: ".25vw", position: "relative" }}>
                      {cmj.LESS.toe_to_heel ? (
                        <Text style={{ color: Theme.TEXT2 }}>(Toe)</Text>
                      ) : (
                        <Text style={{ color: Theme.TEXT2 }}>(Heel)</Text>
                      )}
                    </Col>
                  </Col>
                  <Col column center middle style={{ marginTop: ".5vw" }}>
                    <img
                      src={require("../../assets/images/trunk.png")}
                      style={styles.horizontalImage}
                      resizeMode="contain"
                    />
                    <Col>
                      <Text style={styles.mechanicsTitle}>Trunk Flexion</Text>
                    </Col>
                    <Col style={{ bottom: ".25vw", position: "relative" }}>
                      <Text style={styles.mechanicsTitle}>Initial Contact</Text>
                    </Col>
                    <Col
                      column
                      center
                      middle
                      style={{ bottom: ".25vw", position: "relative" }}
                    >
                      <Text
                        style={{
                          color: cmj.LESS.trunk_flexion_IC
                            ? Theme.GRAD6
                            : Theme.RED,
                          fontWeight: "bold",
                          fontSize: "1vw"
                        }}
                      >
                        {passOrFail(cmj.LESS.trunk_flexion_IC)}
                      </Text>
                    </Col>
                    <Col style={{ bottom: ".25vw", position: "relative" }}>
                      <Text style={{ color: Theme.TEXT2 }}>
                        {cmj.LESS.trunk_flexion_IC ? (
                          <Text style={{ color: Theme.TEXT2 }}>(Flexed)</Text>
                        ) : (
                          <Text style={{ color: Theme.TEXT2 }}>(Extended)</Text>
                        )}
                      </Text>
                    </Col>
                  </Col>
                  <Col column center middle style={{ marginTop: ".5vw" }}>
                    <img
                      src={require("../../assets/images/valgus.png")}
                      style={styles.horizontalImage}
                      resizeMode="contain"
                    />
                    <Col>
                      <Text style={styles.mechanicsTitle}>Total Knee</Text>
                    </Col>
                    <Col style={{ bottom: ".25vw", position: "relative" }}>
                      <Text style={styles.mechanicsTitle}>Valgus</Text>
                    </Col>
                    <Col
                      column
                      center
                      middle
                      style={{ bottom: ".25vw", position: "relative" }}
                    >
                      <Text
                        style={{
                          color: cmj.LESS.knee_valgus_max
                            ? Theme.GRAD6
                            : Theme.RED,
                          fontWeight: "bold",
                          fontSize: "1vw"
                        }}
                      >
                        {passOrFail(cmj.LESS.knee_valgus_max)}
                      </Text>
                    </Col>
                    <Col style={{ bottom: ".25vw", position: "relative" }}>
                      <Text style={{ color: Theme.TEXT2 }}>
                        (L - {Math.round(cmj.LESS.knee_valgus_displacement[0])}
                        °, R -{" "}
                        {Math.round(cmj.LESS.knee_valgus_displacement[1])}°)
                      </Text>
                    </Col>
                  </Col>
                  <Col
                    column
                    center
                    middle
                    style={{ marginTop: ".5vw", right: "4vw" }}
                  >
                    <img
                      src={require("../../assets/images/hipmaxflex.png")}
                      style={styles.horizontalImage}
                      resizeMode="contain"
                    />
                    <Col>
                      <Text style={styles.mechanicsTitle}>Hip Flexion at</Text>
                    </Col>
                    <Col style={{ bottom: ".25vw", position: "relative" }}>
                      <Text style={styles.mechanicsTitle}>
                        Max Knee Flexion
                      </Text>
                    </Col>
                    <Col
                      column
                      center
                      middle
                      style={{ bottom: ".25vw", position: "relative" }}
                    >
                      <Text
                        style={{
                          color: cmj.LESS.hip_flexion_max
                            ? Theme.GRAD6
                            : Theme.RED,
                          fontWeight: "bold",
                          fontSize: "1vw"
                        }}
                      >
                        {passOrFail(cmj.LESS.hip_flexion_max)}
                      </Text>
                    </Col>
                    <Col style={{ bottom: ".25vw", position: "relative" }}>
                      <Text style={{ color: Theme.TEXT2 }}>
                        ({Math.round(cmj.LESS.hip_flexion_displacement)}°)
                      </Text>
                    </Col>
                  </Col>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col>
            <Modal
              open={videoModal}
              onCancel={() => setVideoModal(false)}
              footer={null}
              bodyStyle={{ height: "40vw", padding: "1vw" }}
              style={{ position: "relative", bottom: "2vw" }}
              width={"30vw"}
              className={
                Theme.BACKGROUND === "#000"
                  ? "customAntdModalDark"
                  : "customAntdModalLight"
              }
            >
              <Col
                style={{
                  fontWeight: "bold",
                  // left: "21%",
                  // left: "21%",

                  position: "relative",
                  alignItems: "center"
                }}
                align="middle"
                justify="center"
              >
                <ResultsSectionTitle section={sections[10]} />
                {cmj.video !== null && (
                  <video
                    controls
                    src={cmj.video}
                    style={{
                      width: "15vw",
                      height: "30vw",
                      objectFit: "contain",
                      marginTop: "1vw",
                      position: "relative"
                    }}
                  />
                )}
                {cmj.video === null && (
                  <div center style={{ top: "5vw", position: "relative" }}>
                    <div>
                      <Spin size="large" style={{ marginBottom: "1vw" }} />
                    </div>
                    <Text>Video Processing</Text>
                    <div>
                      <Text style={styles.messageTextVideo}>
                        Check Back Soon
                      </Text>
                    </div>
                  </div>
                )}
              </Col>
            </Modal>
            <Modal
              open={landingModal}
              onCancel={() => setLandingModalVisible(false)}
              footer={null}
              bodyStyle={{ height: "70vw", padding: "1vw" }}
              style={{ position: "relative", bottom: "2vw" }}
              width={"65vw"}
              className={
                Theme.BACKGROUND === "#000"
                  ? "customAntdModalDark"
                  : "customAntdModalLight"
              }
            >
              <Col
                style={{
                  fontWeight: "bold",
                  // left: "21%",
                  // left: "21%",

                  position: "relative",
                  alignItems: "center"
                }}
                align="middle"
                justify="center"
              >
                <Text
                  style={{
                    marginTop: 12,
                    fontSize: "1vw",
                    fontWeight: "600",
                    alignSelf: "center",
                    position: "relative",
                    color: Theme.TEXT
                  }}
                >
                  LANDING MECHANICS
                </Text>
                <Col style={{ marginTop: ".5%", width: "80%" }}>
                  <Text
                    style={{
                      fontSize: ".8vw",
                      position: "relative",
                      // bottom: "1vw",
                      color: Theme.TEXT2,
                      fontWeight: "normal"
                    }}
                  >
                    The landing mechanics score evaluates various aspects of
                    your client's landing technique. Each criterion is assessed
                    as optimal or suboptimal, with a pass (+1) for each optimal
                    criterion. The score is calculated by dividing the total
                    number of optimal criteria by 11.
                  </Text>
                </Col>

                <Row gutter={[16, 16]} style={{ marginTop: "2%" }}>
                  {landing_mechanics.map((section, index) => (
                    <Col style={{marginTop:"1vw"}} span={12} key={index}>
                      <Col>
                        {" "}
                        <Text
                          style={{
                            fontSize: "1vw",
                            fontWeight: "bold",
                            color: Theme.TEXT
                          }}
                        >
                          {section.title}
                        </Text>
                      </Col>
                      <Col>
                        {" "}
                        <Text
                          style={{
                            fontSize: ".8vw",
                            fontWeight: "normal",
                            color: Theme.TEXT2
                          }}
                        >
                          {section.description}.{" "}
                        </Text>
                        <Text
                          style={{
                            fontSize: ".8vw",
                            fontWeight: "normal",
                            color: Theme.TEXT2,
                            fontStyle: "italic"
                          }}
                        >
                          {section.why}
                        </Text>
                      </Col>
                    </Col>
                  ))}
                </Row>
              </Col>
            </Modal>
            {/* <Card
              style={{
                zIndex: 100,
                flex: 1,
                position: "relative",
                width: "15.3vw",
                borderRadius: 10,
                background: Theme.CARD,
                borderWidth: 0
              }}
              className="criclebox h-full"
            >
              <div
                className="aoc"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center"

                  // minHeight: "13vw",
                  // height: 'auto'
                }}
              >
                <Row
                  type="flex"
                  style={{ alignItems: "center" }}
                  justify="center"
                  gutter={10}
                >
                  <Col
                    align="center"
                    span={24}
                    style={{
                      fontWeight: "bold",
                      // marginTop: "5%",
                      // left: "7.5%",
                      width: "100%",
                      position: "relative"
                    }}
                  >
                   
                  </Col>
                </Row>
              </div>
            </Card> */}
          </Col>
        </Row>
      </Col>
    </Col>
  );
};

export default CMJResults;

let textSize = 14;
let modalText = 16;
let compTitle = 20;
let compSectText = 18;
let modalMargin = 0;
let picLeft = "2%";
let avgRight = 160;
let minHeight = 160;

const createStyles = (theme) => ({
  container: {
    alignSelf: "center"
  },
  card: {
    backgroundColor: theme.WHITE,
    //   marginVertical: Theme.SIZES.BASE,
    borderWidth: 0,
    minHeight: minHeight,
    marginBottom: 1,
    marginHorizontal: 1
  },

  cardTitle: {
    flex: 1,
    alignSelf: "center",
    fontWeight: "500"
    // paddingTop: 5,
  },
  cardDescription: {
    // paddingTop: 5,
  },
  imageContainer: {
    borderRadius: 3,
    elevation: 1,
    overflow: "hidden",
    minHeight: 80
  },

  horizontalImage: {
    width: "2vw",
    height: "2vw",
    top: ".25vw",
    position: "relative"
    // position: "absolute",
    // left: "5%",
    // bottom: "1vw",
  },
  videoText: {
    color: theme.BLACK,
    fontSize: 12,
    left: "5.5%",
    marginTop: "1%",
    maxWidth: "60%",
    maxHeight: "50%",
    fontWeight: "bold"
  },
  horizontalStyles: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0
  },
  verticalStyles: {
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0
  },
  fullImage: {
    height: 215
  },

  messageText: {
    color: theme.TEXT,
    fontSize: ".65vw",
    position: "relative",

    fontWeight: "bold",
    bottom: ".5vw"
  },
  mainMessageText: {
    color: theme.TEXT,
    fontSize: ".95vw",
    position: "relative",

    fontWeight: "bold",
    bottom: ".5vw"
  },
  mechanicsTitle: {
    color: theme.TEXT,
    fontSize: ".85vw",
    position: "relative",

    fontWeight: "bold"
  },
  rawMessageText: {
    color: theme.TEXT,
    fontSize: ".95vw",
    position: "relative",

    fontWeight: "bold"
  },
  rawResultText: {
    color: theme.BLUE,
    fontSize: ".95vw",
    position: "relative",

    fontWeight: "bold"
  },
  angleText: {
    color: theme.BLUE,
    fontSize: "1vw",
    position: "relative",

    fontWeight: "bold",
    bottom: ".5vw"
  },
  typeText: {
    color: theme.TEXT,
    fontSize: ".55vw",
    position: "relative",

    fontWeight: "bold",
    bottom: ".75vw"
  },
  messageTextVideo: {
    color: theme.WHITE,
    fontSize: ".5vw",
    fontWeight: "bold",
    paddingVertical: 8,
    paddingRight: 4,
    fontFamily: "Arial"
  },
  compScoreText: {
    color: theme.BLACK,
    fontSize: textSize,
    fontWeight: "bold",
    // paddingVertical: 8,
    // paddingRight: 4,
    fontFamily: "Arial",
    // alignSelf: 'flex-end',

    top: 275
  },
  resultText: {
    color: theme.PRIMARY,
    fontSize: ".75vw",
    position: "relative",

    fontWeight: "bold"
    // marginRight: "5%",
  },
  sectionText: {
    fontWeight: "500",
    fontSize: 19,
    color: theme.BLACK
  },
  compensationTitle: {
    fontWeight: "500",
    fontSize: compTitle,
    marginTop: 30,
    marginBottom: 5,
    color: theme.POP
  },
  scoreText: {
    fontWeight: "500",
    fontSize: ".75vw",
    color: theme.PRIMARY
  },
  movementTitle: {
    textAlign: "left",
    fontSize: "1vw",
    fontWeight: "bold",
    // fontFamily: "ArialBold",
    color: theme.TEXT,
    position: "relative",
    // alignSelf: "center",
    left: ".5%"
  },
  movementScore: {
    fontWeight: "bold",
    fontSize: ".75vw",
    // alignSelf: "center",
    color: theme.PRIMARY,
    left: ".45vw",
    position: "relative"
    // marginTop: 5,
  },
  list: {
    position: "absolute",
    top: 20,
    zIndex: 20,
    flex: 1
  },

  modalView: {
    width: "100%",
    height: "75%",
    // backgroundColor: "black",
    // backgroundColor: "white",
    borderRadius: 20,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2
    },
    alignSelf: "center",
    shadowOpacity: 0.25,
    shadowRadius: 4,
    top: modalMargin
    // elevation: 5,
  },
  bottomView: {
    flex: 1,
    justifyContent: "flex-end",
    top: 20,
    alignSelf: "center"
  },
  infoModalView: {
    width: "100%",
    height: "75%",
    // backgroundColor: "black",
    // backgroundColor: "white",
    borderRadius: 20,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2
    },
    alignSelf: "center",
    shadowOpacity: 0.25,
    shadowRadius: 4
    // elevation: 5,
  },
  button: {
    marginVertical: 15,
    width: 250,
    borderRadius: 15,
    // position: "relative",
    backgroundColor: "white"
  },
  buttontext: {
    color: theme.PRIMARY,
    fontWeight: "bold",
    paddingHorizontal: 5
  },
  modalText: {
    color: theme.WHITE,
    maxWidth: "85%",
    marginBottom: 20,
    textAlign: "center",
    fontSize: modalText,
    marginTop: 5
  },
  video: {
    alignSelf: "center",
    width: "100%",
    height: "95%",
    marginVertical: "10%",
    marginHorizontal: "2%",
    bottom: "8%",

    backgroundColor: "black"
  },
  paragraphHeight: {
    fontSize: ".85vw",
    color: theme.TEXT
  }
});
