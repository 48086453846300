import React from "react";
import { Typography, Layout } from "antd";
import { useDispatch, useSelector } from "react-redux";
import ChatInterface from "./ChatInterface";

const { Title, Paragraph } = Typography;
const { Content, Sider } = Layout;

const AISummary = ({ id, type, aiSummary }) => {
    const { user, Theme } = useSelector((state) => state.auth);

  return (
    <Layout style={{ height: "100%" }}>
      {/* Left Side: AI Summary */}
      <Sider
        width="50%"
        style={{
          backgroundColor: Theme.CARD,
          padding: "1vw",
          overflowY: "auto",
        }}
      >
        <Title
          level={4}
          style={{
            marginBottom: "1vw",
            color: Theme.TEXT,
            textAlign: 'center',
          }}
        >
          Summary
        </Title>
        <Paragraph
          style={{
            fontSize: "1vw",
            lineHeight: "1.5vw",
            whiteSpace: "pre-line", 
            color: Theme.TEXT,
          }}
        >
          {aiSummary}
        </Paragraph>
      </Sider>
      <Content>
        <ChatInterface id={id} type={type} theme={Theme} />
      </Content>
    </Layout>
  );
};

export default AISummary;
