import { useEffect, useState } from "react";

import {
  Row,
  Col,
  Card,
  Descriptions,
  message,
  Typography,
  Input,
  Modal,
  Switch,
} from "antd";
import { updatePhoto } from "../redux/actions/clients";
import { REACT_APP_API_URL } from "../env";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteAccount,
  load_user,
  updateEmail,
  updatePass,
  updateProfile,
} from "../redux/actions/auth";
import { getSingleTrainer } from "../redux/actions/trainers";
import genericPofile from "../assets/images/profilepic.jpg";
import { IoCameraOutline, IoPencilSharp } from "react-icons/io5";

import Theme from "../constants/Theme";
import { Button } from "react-bootstrap";
import { StyleSheet, css } from "aphrodite";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import * as yup from "yup";

function Profile({ user_account }) {
  const { user, isAuthenticated, Theme } = useSelector((state) => state.auth);
  const { trainer } = useSelector((state) => state.trainers);

  if (user_account == "1") {
    user = trainer;
  }
  const { subscriptionStatus, compedMembership } = useSelector(
    (state) => state.payment
  );
  const [darkChecked, setDarkChecked] = useState(user?.profile?.dark_mode);
  const [measurementChecked, setMeasurementChecked] = useState(user?.profile?.measurement === "Imperial");
  const [loading, setLoading] = useState(false);
  const [hideButtons, setHideButtons] = useState(false);
  const [emailFormVisible, setEmailFormVisible] = useState(false);
  const [passFormVisible, setPassFormVisible] = useState(false);
  const [deactFormVisible, setDeactFormVisible] = useState(false);
  const [new_email, setEmailInputValue] = useState("");
  const [re_new_email, setReEmailInputValue] = useState("");
  const [new_password, setNewPassInputValue] = useState("");
  const [re_new_password, setNewRePassInputValue] = useState("");
  const [current_password, setPassInputValue] = useState("");
  const [first_name, setFirstNameValue] = useState(user?.profile?.first_name);
  const [last_name, setLastNameValue] = useState(user?.profile?.last_name);
  const [email, setEmailValue] = useState(user?.profile?.email);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [firstNamePlaceHolder, setFirstNamePlaceholder] = useState(first_name);
  const [lastNamePlaceHolder, setLastNamePlaceholder] = useState(last_name);
  const [emailPlaceHolder, setEmailPlaceHolder] = useState(email);
  const [emailError, setEmailError] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");

  const dispatch = useDispatch();
  const fetchUser = () => dispatch(load_user());
  useEffect(() => {
    fetchUser();
    setDarkChecked(user?.profile?.dark_mode);
    setMeasurementChecked(user?.profile?.measurement === "Imperial");
  }, []);
  useEffect(() => {
    setDarkChecked(user?.profile?.dark_mode);
     setMeasurementChecked(user?.profile?.measurement === "Imperial");
  }, [user]);
  const history = useHistory();

  const { Text } = Typography;

  const submitNewEmail = async (e) => {
    dispatch(updateEmail(new_email, re_new_email, current_password));

    setTimeout(() => {
      setEmailInputValue("");
      setReEmailInputValue("");
      setPassInputValue("");

      setEmailFormVisible(false);
      setHideButtons(false);
      dispatch(load_user());
    }, 1000);
  };

  const submitNewPass = async (e) => {
    dispatch(updatePass(new_password, re_new_password, current_password));
    setTimeout(() => {
      setPassInputValue("");
      setNewPassInputValue("");
      setNewRePassInputValue("");
      setPassFormVisible(false);
      setHideButtons(false);
      dispatch(load_user());
    }, 1000);
  };

  const submitDelete = async (e) => {
    dispatch(deleteAccount(current_password));
  };

  const cancelUpdate = () => {
    setEmailFormVisible(false);
    setPassFormVisible(false);
    setDeactFormVisible(false);
    setHideButtons(false);
    setEmailInputValue("");
    setReEmailInputValue("");
    setNewPassInputValue("");
    setNewRePassInputValue("");
    setPassInputValue("");
  };

  const createStripePortal = async () => {
    const token = await localStorage.getItem("access");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + token,
      },
    };
    const res = await axios.post(
      `${REACT_APP_API_URL}/api/stripeportal/`,
      null,
      config
    );
    if (res.status === 200) {
      window.location.href = res.data.portal_url;
    }
  };

  const [isRowLayout, setIsRowLayout] = useState(window.innerWidth >= 1300);

  // Function to handle window resize event and update layout mode
  const handleWindowResize = () => {
    setIsRowLayout(window.innerWidth >= 768);
  };

  useEffect(() => {
    // Add event listener for window resize
    window.addEventListener("resize", handleWindowResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  var trainerPhotoPath = genericPofile;
  const [trainerPhoto, setTrainerPhoto] = useState(trainerPhotoPath);

  useEffect(() => {
    if (user?.profile?.photo != null) {
      setTrainerPhoto(user?.profile?.photo);
    }
    setFirstNameValue(firstNamePlaceHolder);
    setLastNameValue(lastNamePlaceHolder);
    setEmailValue(emailPlaceHolder);
  }, [user]);

  const handleImageChange = (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = async () => {
        setTrainerPhoto(reader.result);
        dispatch(
          updatePhoto(user?.profile?.id, selectedFile, user?.profile?.account_type)
        );
      };
      reader.readAsDataURL(selectedFile);
    }
  };

  const handleImageClick = () => {
    document.getElementById("imageInput").click();
  };

  const handleCancel = () => {
    setEditModalVisible(false);
  };

  const onFirstNameChange = (e) => {
    // Update the first_name state variable here
    setFirstNamePlaceholder(e.target.value);
  };
  const onLastNameChange = (e) => {
    // Update the LAst_name state variable here
    setLastNamePlaceholder(e.target.value);
  };
  const onEmailChange = (e) => {
    // Update the Emaile state variable here
    setEmailPlaceHolder(e.target.value);
  };
  const isFadedIn = true;
  const submitUpdate = async (e) => {
    e.preventDefault(); // Prevent form submission

    try {
      // Define validation schema
      const validationSchema = yup.object().shape({
        firstName: yup.string().required("First Name is required"),
        lastName: yup.string().required("Last Name is required"),
        email: yup
          .string()
          .email("Invalid email format")
          .matches(/^.+@.+\..+$/, "Invalid email format")
          .required("Email is required"),
        email: yup
          .string()
          .email("Invalid email format")
          .required("Email is required"),
      });

      // Create an object with the values to validate
      const valuesToValidate = {
        firstName: firstNamePlaceHolder,
        lastName: lastNamePlaceHolder,
        email: emailPlaceHolder,
      };

      // Validate the values
      await validationSchema.validate(valuesToValidate, { abortEarly: false });
      const modalStyle = {
        background: "#ffcccc", // Set your desired background color here
      };
      // If validation succeeds, update the state and dispatch the update action
      setFirstNameValue(firstNamePlaceHolder);
      setLastNameValue(lastNamePlaceHolder);
      setEmailValue(emailPlaceHolder);

      // Clear any previous error messages
      setEmailError("");
      // Clear other error messages for other fields if needed

      dispatch(
        updateProfile({
          first_name: firstNamePlaceHolder,
          last_name: lastNamePlaceHolder,
          email: emailPlaceHolder,
        })
      );
      setEmailError("");
      setLastNameError("");
      setFirstNameError("");
      setEditModalVisible(false);
    } catch (error) {
      // Handle validation errors
      const validationErrors = {};
      if (error.inner) {
        error.inner.forEach((err) => {
          validationErrors[err.path] = err.message;
        });
      }

      // Update error state variables for specific fields
      if (validationErrors.email) {
        setEmailError(validationErrors.email);
      }
      if (validationErrors.firstName) {
        setFirstNameError(validationErrors.firstName);
      }
      if (validationErrors.lastName) {
        setLastNameError(validationErrors.lastName);
      }
    }
  };

  return (
    <>
      <Col
        align="middle"
        justify="center"
        style={{
          position: "relative",
          right: "6vw",
          bottom: isRowLayout ? "0vw" : "5vh"
        }}
        className={`fade-in-slide-up-element ${
          isFadedIn ? "fade-in-slide-up-animation" : ""
        }`}
        gutter={[24, 0]}
      >
        <Col align={"center"} md={isRowLayout ? 8 : 24} className="mb-24">
          <Card
            bordered={false}
            className="header-solid h-full"
            style={{ background: Theme.CARD, width: "25vw" }}
          >
            <div style={{ position: "relative", top: "5vw" }}>
              <Col align={"center"}>
                <div
                  style={{
                    bottom: ".5vw",
                    // right: "6vw",
                    position: "relative",
                    borderRadius: 100,
                    cursor: "pointer"
                  }}
                >
                  <img
                    src={trainerPhoto}
                    alt=""
                    style={{
                      width: "6.25vw",
                      height: "6vw",
                      top: ".75vw",
                      position: "relative",
                      borderRadius: 100,
                      cursor: "pointer",
                      objectFit: "cover"
                    }}
                    object-fit="cover"
                    onClick={handleImageClick}
                    title="Upload"
                  />
                  <div
                    onClick={handleImageClick}
                    style={{
                      height: "1.75vw",
                      width: "1.75vw",
                      top: "5vw",
                      left: "55%",
                      position: "absolute",
                      borderRadius: 50,
                      cursor: "pointer",
                      opacity: 0.9,
                      backgroundImage:
                        "linear-gradient(45deg, #C04848 0%, #480048 80%)"
                    }}
                  >
                    <IoCameraOutline
                      style={{
                        position: "relative",
                        zIndex: 60,
                        top: "12.5%",
                        right: "1.5%",
                        // left: "16.5%",
                        fontSize: "1.25vw",
                        color: Theme.WHITE
                      }}
                    />
                  </div>
                  <input
                    id="imageInput"
                    type="file"
                    style={{ display: "none" }}
                    onChange={handleImageChange}
                  />
                </div>

                <div flex>
                  <div middle style={{ marginTop: "5%" }}>
                    {!hideButtons && (
                      <Text bold style={{ fontSize: "1vw", color: Theme.TEXT }}>
                        {first_name} {last_name}
                        <span
                          style={{
                            top: 0,
                            left: ".25vw",
                            position: "relative",
                            color: Theme.PRIMARY,
                            cursor: "pointer"
                          }}
                          onClick={() => setEditModalVisible(true)}
                        >
                          <IoPencilSharp size={".75vw"} />
                        </span>
                      </Text>
                    )}
                    {!hideButtons && (
                      <div>
                        <Text
                          size={18}
                          style={{ fontSize: "1vw", color: Theme.TEXT }}
                        >
                          {email}
                        </Text>
                      </div>
                    )}

                    {passFormVisible && (
                      <div style={{ top: "8%" }}>
                        <div style={styles.fieldrow}>
                          <Input
                            className={
                              Theme.BACKGROUND === "#000"
                                ? "custom-placeholder-color-dark"
                                : "custom-placeholder-color-light"
                            }
                            style={{
                              width: "10vw",
                              color: Theme.TEXT,
                              background: Theme.BACKGROUND,
                              borderWidth: 0,
                              fontSize: ".75vw"
                            }}
                            placeholder="Current Password"
                            name="current password"
                            onChange={(text) => setPassInputValue(text)}
                            // value={current_password}
                          />
                        </div>
                        <div style={styles.fieldrow}>
                          <Input
                            style={{
                              width: "10vw",
                              color: Theme.TEXT,
                              background: Theme.BACKGROUND,
                              borderWidth: 0,
                              fontSize: ".75vw",
                              marginTop: ".5vw",
                              marginBottom: ".5vw"
                            }}
                            editable
                            type="password"
                            placeholder="New Password"
                            className={
                              Theme.BACKGROUND === "#000"
                                ? "custom-placeholder-color-dark"
                                : "custom-placeholder-color-light"
                            }
                            name="password"
                            onChange={(text) => setNewPassInputValue(text)}
                            // value={new_password}
                            autoCapitalize="none"
                          />
                        </div>
                        <div style={styles.fieldrow}>
                          <Input
                            className={
                              Theme.BACKGROUND === "#000"
                                ? "custom-placeholder-color-dark"
                                : "custom-placeholder-color-light"
                            }
                            style={{
                              width: "10vw",
                              color: Theme.TEXT,
                              background: Theme.BACKGROUND,
                              borderWidth: 0,
                              fontSize: ".75vw"
                            }}
                            editable
                            type="password"
                            placeholder="Re-Type New Password"
                            name="re_password"
                            onChange={(text) => setNewRePassInputValue(text)}
                            // value={re_new_password}
                            autoCapitalize="none"
                          />
                        </div>

                        <div
                          middle
                          row
                          space="evenly"
                          style={{ top: ".5vw", position: "relative" }}
                        >
                          <Button
                            small
                            style={{
                              backgroundColor: Theme.BACKGROUND,
                              borderRadius: 5,
                              borderWidth: 0,
                              cursor: "pointer",
                              width: "10vw",
                              padding: "2%",
                              marginRight: ".5vw",
                              minWidth: "4vw",
                              boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.175)" // Adding a shadow effect
                            }}
                            onClick={(e) => submitNewPass(e)}
                          >
                            <Text
                              style={{
                                color: Theme.TEXT,
                                fontWeight: "bold"
                              }}
                            >
                              Save
                            </Text>
                          </Button>
                          <Button
                            small
                            style={{
                              backgroundColor: Theme.BACKGROUND,
                              borderRadius: 5,
                              borderWidth: 0,
                              cursor: "pointer",
                              width: "10vw",
                              padding: "2%",
                              marginRight: ".5vw",
                              minWidth: "4vw",
                              boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.175)" // Adding a shadow effect
                            }}
                            onClick={() => cancelUpdate()}
                          >
                            <Text
                              style={{
                                color: Theme.TEXT,
                                fontWeight: "bold"
                              }}
                            >
                              Cancel
                            </Text>
                          </Button>
                        </div>
                      </div>
                    )}
                    {deactFormVisible && (
                      <div style={{ top: "8%" }}>
                        <div style={styles.fieldrow}>
                          <Input
                            className={
                              Theme.BACKGROUND === "#000"
                                ? "custom-placeholder-color-dark"
                                : "custom-placeholder-color-light"
                            }
                            placeholder="Current Password"
                            placeholderTextColor={Theme.TEXT}
                            name="current password"
                            secureTextEntry={true}
                            onChange={(text) => setPassInputValue(text)}
                            style={{
                              width: "10vw",
                              color: Theme.TEXT,
                              background: Theme.BACKGROUND,
                              borderWidth: 0,
                              fontSize: ".75vw"
                            }}
                          />
                        </div>
                        <div
                          middle
                          row
                          space="evenly"
                          style={{ top: "1vw", position: "relative" }}
                        >
                          <Button
                            small
                            style={{
                              backgroundColor: Theme.BACKGROUND,
                              borderRadius: 5,
                              borderWidth: 0,
                              cursor: "pointer",
                              width: "10vw",
                              padding: "2%",
                              marginRight: ".5vw",
                              minWidth: "4vw",
                              boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.175)" // Adding a shadow effect
                            }}
                            onClick={(e) => submitDelete(e)}
                          >
                            <Text
                              style={{
                                color: Theme.TEXT,
                                fontWeight: "bold"
                              }}
                            >
                              Delete
                            </Text>
                          </Button>
                          <Button
                            small
                            style={{
                              backgroundColor: Theme.BACKGROUND,
                              borderRadius: 5,
                              borderWidth: 0,
                              cursor: "pointer",
                              width: "10vw",
                              padding: "2%",
                              marginRight: ".5vw",
                              minWidth: "4vw",
                              boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.175)" // Adding a shadow effect
                            }}
                            onClick={() => cancelUpdate()}
                          >
                            <Text
                              style={{
                                color: Theme.TEXT,
                                fontWeight: "bold"
                              }}
                            >
                              Cancel
                            </Text>
                          </Button>
                        </div>
                      </div>
                    )}
                  </div>
                  {!hideButtons && (
                    <div
                      center
                      column
                      style={{ position: "relative", marginTop: ".5vw" }}
                    >
                      {" "}
                      <Col>
                        {" "}
                        <Text
                          size={18}
                          style={{ fontSize: ".85vw", color: Theme.TEXT }}
                        >
                          Current {user?.profile.client_label} Limit
                        </Text>
                        <div>
                          <Text
                            size={18}
                            style={{
                              fontSize: ".85vw",
                              color: Theme.PRIMARY,
                              fontWeight: "bold"
                            }}
                          >
                            {user?.stats?.client_limit}
                          </Text>
                        </div>
                      </Col>
                      {user?.profile?.organization === null && (
                        <div center column>
                          {user?.profile?.stripe_paid === true ? (
                            <Button
                              small
                              style={{
                                backgroundColor: Theme.BACKGROUND,
                                borderRadius: 5,
                                borderWidth: 0,
                                cursor: "pointer",
                                width: "15vw",
                                padding: "2%",
                                // marginRight: ".5vw",
                                marginTop: ".75vw",
                                boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.175)" // Adding a shadow effect
                              }}
                              onClick={() => {
                                createStripePortal();
                              }}
                            >
                              <Text
                                style={{
                                  color: Theme.TEXT,
                                  fontWeight: "bold",
                                  fontSize: ".75vw",
                                  padding: 10,
                                  textAlign: "center"
                                }}
                              >
                                Manage Subscription
                              </Text>
                            </Button>
                          ) : (
                            <Button
                              small
                              style={{
                                backgroundColor: Theme.BACKGROUND,
                                borderRadius: 5,
                                borderWidth: 0,
                                cursor: "pointer",
                                width: "15vw",
                                padding: "2%",
                                // marginRight: ".5vw",
                                marginTop: ".75vw",
                                boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.175)" // Adding a shadow effect
                              }}
                              onClick={() => {
                                history.push("./subscribe");
                              }}
                            >
                              <Text
                                style={{
                                  color: Theme.TEXT,
                                  fontWeight: "bold",
                                  fontSize: ".75vw",
                                  padding: 10,
                                  textAlign: "center"
                                }}
                              >
                                Upgrade Subscription
                              </Text>
                            </Button>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                  {!hideButtons && (
                    <div
                      center
                      column
                      style={{ position: "relative", top: "1vw" }}
                    >
                      <Col center>
                        <Button
                          small
                          style={{
                            backgroundColor: Theme.BACKGROUND,
                            borderRadius: 5,
                            borderWidth: 0,
                            padding: "2%",
                            cursor: "pointer",

                            width: "15vw",
                            boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.175)" // Adding a shadow effect
                          }}
                          onClick={() => {
                            setPassFormVisible(true);
                            setHideButtons(true);
                          }}
                        >
                          <Text
                            style={{
                              fontWeight: "bold",
                              fontSize: ".75vw",
                              padding: 10,
                              color: Theme.TEXT,
                              textAlign: "center"
                            }}
                          >
                            Reset Password
                          </Text>
                        </Button>
                        <div>
                          {user?.profile?.organization == null && (
                            <Button
                              small
                              style={{
                                backgroundColor: Theme.BACKGROUND,
                                borderRadius: 5,
                                borderWidth: 0,
                                cursor: "pointer",
                                width: "15vw",
                                padding: "2%",
                                marginTop: "1vw",
                                boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.175)" // Adding a shadow effect
                              }}
                              onClick={() => {
                                setDeactFormVisible(true);
                                setHideButtons(true);
                              }}
                            >
                              <Text
                                style={{
                                  color: Theme.TEXT,
                                  fontWeight: "bold",
                                  fontSize: ".75vw",
                                  padding: 10,
                                  textAlign: "center"
                                }}
                              >
                                Delete Account
                              </Text>
                            </Button>
                          )}
                        </div>
                      </Col>
                    </div>
                  )}

                  {!hideButtons && (
                    <Row align={"middle"} justify={"center"}>
                      <Col
                        align={"middle"}
                        justify={"center"}
                        style={{
                          position: "relative",
                          marginBottom: ".5vw",
                          top: "2vw",
                          marginRight: "2vw"
                        }}
                      >
                        {Theme.BACKGROUND === "#f1f1fb" && (
                          <p
                            style={{
                              color: Theme.TEXT,
                              position: "relative",
                              top: ".5vw",
                              fontSize: ".65vw"
                            }}
                          >
                            Light Mode
                          </p>
                        )}
                        {Theme.BACKGROUND === "#000" && (
                          <p
                            style={{
                              color: Theme.TEXT,
                              position: "relative",
                              top: ".5vw",
                              fontSize: ".65vw"
                            }}
                          >
                            Dark Mode
                          </p>
                        )}

                        <div>
                          <Switch
                            defaultChecked={darkChecked}
                            onChange={(checked) =>
                              dispatch(
                                updateProfile(user?.profile?.id, {
                                  dark_mode: checked
                                }),
                                setDarkChecked(checked)
                              )
                            }
                          />
                        </div>
                      </Col>
                      <Col
                        align={"middle"}
                        justify={"center"}
                        style={{
                          position: "relative",
                          marginBottom: ".5vw",
                          top: "2vw"
                        }}
                      >
                        <p
                          style={{
                            color: Theme.TEXT,
                            position: "relative",
                            top: ".5vw",
                            fontSize: ".65vw"
                          }}
                        >
                          {user?.profile?.measurement}
                        </p>

                        <div>
                          <Switch
                            defaultChecked={measurementChecked}
                            onChange={(checked) => {
                         
                              if (user?.profile?.measurement === "Imperial") {
                                dispatch(
                                  updateProfile(user?.profile?.id, {
                                    measurement: "Metric"
                                  })
                                );
                              } else {
                                dispatch(
                                  updateProfile(user?.profile?.id, {
                                    measurement: "Imperial"
                                  })
                                );
                              }
                              setMeasurementChecked(checked);
                            }}
                          />
                        </div>
                      </Col>
                    </Row>
                  )}
                </div>
              </Col>
            </div>

            <div middle style={styles.legalCard}></div>
            <div style={{ height: 200 }}></div>
          </Card>
        </Col>
        <Col
          justify="center"
          align="center"
          md={8}
          style={{ height: "20vw", alignSelf: "center", marginLeft: ".75%" }}
          className="mb-24"
        >
          <Card
            bordered={false}
            // className="header-solid h-full card-profile-information"
            // bodyStyle={{ paddingTop: 0, paddingBottom: 16 }}
            style={{ fontSize: "1vw", background: Theme.CARD }}
          >
            <span className="text-dark"></span>
            <hr className="my-25" />
            <Descriptions></Descriptions>
            <div middle column style={{ bottom: "3%" }}>
              <Col>
                <a
                  href="https://www.demotuai.com/#/eula"
                  style={{ color: Theme.PRIMARY, fontSize: ".75vw" }}
                  className="font-bold"
                >
                  Terms of Service{" "}
                </a>{" "}
                <div>
                  <a
                    href="https://www.demotuai.com/#/privacy"
                    style={{ color: Theme.PRIMARY, fontSize: ".75vw" }}
                    className="font-bold"
                  >
                    Privacy Policy{" "}
                  </a>
                </div>
              </Col>
            </div>
          </Card>
          <Modal
            open={editModalVisible}
            onCancel={handleCancel}
            className={
              Theme.BACKGROUND === "#000"
                ? "customAntdModalDark"
                : "customAntdModalLight"
            }
            footer={[null]}
          >
            <form
              onSubmit={(e) => submitUpdate(e)}
              style={{ background: Theme.CARD, paddingBottom: "15%" }}
            >
              <Col align="center" style={{ top: "1vw", position: "relative" }}>
                <Col style={styles.fieldrow}>
                  <Input
                    style={{
                      width: "10vw",
                      color: Theme.TEXT,
                      background: Theme.BACKGROUND,
                      borderWidth: 0
                    }}
                    placeholder={"First Name"}
                    name="current password"
                    onChange={(e) => onFirstNameChange(e)}
                    className={
                      Theme.BACKGROUND === "#000"
                        ? "custom-placeholder-color-dark"
                        : "custom-placeholder-color-light"
                    }
                    defaultValue={first_name}
                  />
                  <div style={{ marginTop: ".5vw" }}>
                    <Text style={{ color: "red" }}>{firstNameError}</Text>
                  </div>
                </Col>
                <div style={{ marginTop: ".5vw", marginBottom: ".5vw" }}>
                  <Input
                    style={{
                      width: "10vw",
                      color: Theme.TEXT,
                      background: Theme.BACKGROUND,
                      borderWidth: 0
                    }}
                    onChange={(e) => onLastNameChange(e)}
                    placeholder={"Last Name"}
                    className={
                      Theme.BACKGROUND === "#000"
                        ? "custom-placeholder-color-dark"
                        : "custom-placeholder-color-light"
                    }
                    defaultValue={last_name}
                  />
                  <div style={{ marginTop: ".5vw" }}>
                    <Text style={{ color: "red" }}>{lastNameError}</Text>
                  </div>
                </div>
                <div style={styles.fieldrow}>
                  <Input
                    onChange={(e) => onEmailChange(e)}
                    defaultValue={email}
                    className={
                      Theme.BACKGROUND === "#000"
                        ? "custom-placeholder-color-dark"
                        : "custom-placeholder-color-light"
                    }
                    style={{
                      width: "10vw",
                      color: Theme.TEXT,
                      background: Theme.BACKGROUND,
                      borderWidth: 0
                    }}
                  />
                  <div style={{ marginTop: ".5vw" }}>
                    <Text style={{ color: "red" }}>{emailError}</Text>
                  </div>
                </div>
                <div
                  middle
                  row
                  space="evenly"
                  style={{ top: "1vw", position: "relative" }}
                >
                  <Button
                    small
                    style={{
                      backgroundColor: Theme.BACKGROUND,
                      borderRadius: 5,
                      borderWidth: 0,
                      padding: "2%",
                      marginRight: ".5vw",
                      minWidth: "4vw",
                      cursor: "pointer",
                      boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.175)" // Adding a shadow effect
                    }}
                    onClick={(e) => submitUpdate(e)}
                  >
                    <Text
                      style={{
                        color: Theme.TEXT,
                        fontWeight: "bold"
                      }}
                    >
                      Save
                    </Text>
                  </Button>
                </div>
              </Col>
            </form>
          </Modal>
        </Col>
      </Col>
    </>
  );
}

export default Profile;

const styles = StyleSheet.create({
  profile: {
    customModal: {
      backgroundColor: "black !important",
    },
    // marginBottom: 50,
    shadowColor: Theme.BLACK,
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 4,
    shadowOpacity: 0.1,
    // justifyContent: "flex-start",.
    flex: 1,
  },
  profileContainer: {
    // height: '100%',
    shadowColor: Theme.BLACK,
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 4,
    shadowOpacity: 0.5,
    elevation: 2,
    zIndex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  profileBackground: {
    // width: width,
    // height: height / 2,
  },
  profileCard: {
    // position: "relative",
    // paddingTop: 25,
    marginBottom: "5%",
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    // backgroundColor: Theme.WHITE,
    shadowColor: Theme.BLACK,
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 4,
    shadowOpacity: 0.5,
    zIndex: 2,
    elevation: 2,
  },
  legalCard: {
    // position: "relative",
    // paddingTop: 25,
    marginBottom: "25%",
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    // backgroundColor: Theme.WHITE,
    shadowColor: Theme.BLACK,
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 4,
    shadowOpacity: 0.5,
    zIndex: 2,
    elevation: 2,
  },
  info: {
    paddingHorizontal: 40,
    elevation: 2,
  },

  avatar: {
    height: "45%",
    width: "40%",
    borderRadius: 62,
    borderWidth: 0,
    bottom: "15%",
  },
  nameInfo: {
    // marginTop: '4%',
    bottom: "25%",
  },
  divider: {
    width: "90%",
    borderWidth: 1,
    borderColor: "#E9ECEF",
  },
  scroll: {
    borderRadius: 5,
    height: "200%",
    top: 50,
    // marginBottom: 500,
  },
  button: {
    backgroundColor: Theme.WHITE,
    height: 45,
    width: 90,
    marginBottom: "10%",
    shadowColor: Theme.BLACK,
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 4,
    shadowOpacity: 0.5,
  },
  saveButton: {
    backgroundColor: Theme.WHITE,
    height: 45,
    width: "40%",
    marginBottom: "10%",
    shadowColor: Theme.BLACK,
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 4,
    shadowOpacity: 0.5,
  },
  legalText: {
    color: Theme.BLACK,
    marginVertical: "2%",
  },
  input: {
    // backgroundColor: "#fff",
    borderBottomColor: Theme.PRIMARY,
    borderColor: "transparent",
    borderWidth: 1,
    paddingHorizontal: 10,
    paddingBottom: 10,
    // margin: 10,
    // borderRadius: 5,
    color: Theme.WHITE,
    maxWidth: "100%",
    minWidth: "70%",
  },
  bottomInputs: {
    // backgroundColor: "#fff",
    borderBottomColor: Theme.PRIMARY,
    borderColor: "transparent",
    borderWidth: 1,
    padding: 10,
    // margin: 10,
    // borderRadius: 5,

    color: Theme.BLACK,
    maxWidth: "100%",
    minWidth: "70%",
  },
  fieldrow: {
    flexDirection: "row",
    alignItems: "center",
  },
  modalView: {
    backgroundColor: "black",
    backgroundColor: "white",
    borderRadius: 10,
    // alignItems: "center",
    top: 15,
    // alignSelf: "center",
  },
  bottomView: {
    flex: 1,
    // justifyContent: "flex-end",
    top: 25,
    // alignSelf: "center",
  },
});
