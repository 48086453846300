import React, { useEffect } from "react";
import lottie from "lottie-web";
import animationData from "../../assets/resultsLoader.json"; // Replace with your Lottie animation JSON file

const LottieResults = () => {
  useEffect(() => {
    // Use lottie.loadAnimation() to load the animation into a DOM element
    const animationContainer = document.getElementById("lottie-container");
    if (animationContainer) {
      lottie.loadAnimation({
        container: animationContainer,
        animationData: animationData,
        loop: true, // Set to true if you want the animation to loop
        autoplay: true, // Set to true to play the animation immediately
      });
    }
  }, []);

  return (
    <div  id="lottie-container"
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "7vw", // Adjust the height of the container
      width: "7vw", // Adjust the width of the container
    }}></div>
  );
};

export default LottieResults;