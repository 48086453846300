import React, { useState, useEffect, useRef } from "react";
import { Input, Button, List, Avatar, Spin } from "antd";
import { SendOutlined, LoadingOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { REACT_APP_ASGI_URL } from "../../env";
import ReactMarkdown from "react-markdown";
import userImage from "../../assets/images/borelli.png"
import aiImage from "../../assets/images/profilepic.jpg"

const ChatInterface = ({ id, type }) => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [loading, setLoading] = useState(false);
  const [typing, setTyping] = useState(false);
  const socketRef = useRef(null);
  const { user, Theme } = useSelector((state) => state.auth);

  const suggestions = [
    "Can you provide deeper insights into this assessment?",
    "What are the risks for not addressing the issues found?",
    "Are there any additional exercise recommendations?",
  ];

  const typingEffect = (text) => {
    setTyping(true);
    const words = text.split(" ");
    let index = 0;

    const interval = setInterval(() => {
      if (index < words.length) {
        setMessages((prev) => {
          const lastMessage = prev[prev.length - 1];
          return [
            ...prev.slice(0, -1),
            { sender: "AI", text: `${lastMessage.text} ${words[index]}`.trim() },
          ];
        });
        index++;
      } else {
        clearInterval(interval);
        setTyping(false);
        setLoading(false);
      }
    }, 50);
  };

  useEffect(() => {
    const socket = new WebSocket(`${REACT_APP_ASGI_URL}/ws/chat/${type}/${id}/`);
    socketRef.current = socket;

    socket.onmessage = (event) => {
      const data = JSON.parse(event.data);

      setMessages((prev) => {
        if (prev.length > 0 && prev[prev.length - 1].loading) {
          const updatedMessages = [...prev];
          updatedMessages[updatedMessages.length - 1] = {
            ...updatedMessages[updatedMessages.length - 1],
            text: "",
            loading: false,
          };
          typingEffect(data.message);
          return updatedMessages;
        } else {
          return [...prev, { sender: "AI", text: data.message }];
        }
      });
    };

    return () => socket.onclose = () => console.log("WebSocket closed.");
  }, [id, type]);


  const sendMessage = (message) => {
    const messageToSend = message || input;
    if (socketRef.current && messageToSend.trim()) {
        setLoading(true);
        socketRef.current.send(JSON.stringify({ message: messageToSend }));
        setMessages((prev) => [
          ...prev,
          { sender: "User", text: messageToSend },
          { sender: "AI", text: "", loading: true },
        ]);
        setInput("");
      }
  };

  return (
    <div
      style={{
        height: "100%",
        padding: "0.5vw",
        display: "flex",
        flexDirection: "column",
        backgroundColor: Theme.CARD,
        color: Theme.TEXT,
      }}
    >
      <div style={{ flex: 1, overflowY: "auto", marginBottom: "vw" }}>
        <List
          dataSource={messages}
          renderItem={(msg) => (
            <List.Item
              style={{
                justifyContent: "flex-start",
              }}
            >
              <List.Item.Meta
                avatar={
                  <Avatar
                    src={
                      msg.sender === "AI"
                        ? userImage 
                        : aiImage
                    }
                    style={{
                        marginTop: "1vw", 
                      }}
                  />
                
                }
                
                description={
                  msg.loading ? (
                    <Spin indicator={<LoadingOutlined />} style={{marginTop: "1.25vw"}} />
                  ) : msg.sender === "AI" ? (
                    <div style={{color: Theme.TEXT}}>
                    <ReactMarkdown
                        components={{
                            p: ({ node, ...props }) => (
                            <p
                                style={{
                                    fontSize: "0.95vw",
                                    color: Theme.TEXT, 
                                }}
                                {...props}
                            />
                            ),
                            h1: ({ node, ...props }) => (
                                <h1
                                  style={{
                                    color: Theme.TEXT,
                                  }}
                                  {...props}
                                />
                              ),
                              h2: ({ node, ...props }) => (
                                <h2
                                  style={{
                                    color: Theme.TEXT,
                                  }}
                                  {...props}
                                />
                              ),
                              h3: ({ node, ...props }) => (
                                <h3
                                  style={{
                                    color: Theme.TEXT,
                                  }}
                                  {...props}
                                />
                              ),
                        }}
                        >
                        {msg.text}
                        </ReactMarkdown>
                        </div>
                    
                  ) : (
                    <p style={{ whiteSpace: "pre-line", color: Theme.TEXT, fontSize: '0.95vw'}}>
                    {msg.text}
                  </p>
                  )
                }
              />
            </List.Item>
          )}
        />
         {messages.length === 1 && messages[0]?.sender === "AI" && !typing && (
        <div style={{ color: Theme.TEXT }}>
          {suggestions.map((suggestion, index) => (
            <Button
              key={index}
              style={{ marginRight: "0.5vw", color: Theme.TEXT, backgroundColor:Theme.CARD }}
              onClick={() => {
                setInput(suggestion); 
                sendMessage(suggestion); 
              }}
            >
              {suggestion}
            </Button>
          ))}
        </div>
      )}
      </div>
      <div style={{ display: "flex", alignItems: "center", gap: "1vw" }}>
        <Input
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder="Ask Borelli anything..."
          style={{ flex: 1, backgroundColor: Theme.INPUT, color: Theme.TEXT, fontSize: '0.9vw', fontWeight: "normal" }}
          disabled={loading}
        />
        <Button
        icon={<SendOutlined />}
        onClick={sendMessage}
        disabled={loading}
        style={{
            backgroundColor: Theme.PRIMARY,
            borderColor: Theme.PRIMARY,
            //color: "#fff", // Adjust text color for contrast
        }}
    />
      </div>
     
    </div>
  );
};
export default ChatInterface;